import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';
import { getFieldValidator } from './companyInfo.validators';
import { Region, setRegion } from '../region/regionSlice';
import { config } from '../../config';

export interface BrasilCity {
    id: number;
    nome: string;
    uf: {
        id: number;
        sigla: string;
    };
}

// eslint-disable-next-line no-shadow
export enum TaxEntityType {
    VAT,
    LegalEntity,
    NaturalEntity,
}

export interface CompanyInfoFields {
    countryCode: string;
    companyName: string;
    vat: string;
    taxEntity: TaxEntityType;
    zipCode: string;
    street: string;
    houseNo: string;
    addressDetails: string;
    neighborhood: string;
    city: string;
    cityId: string;
    phone: string;
    state: string;
}

export type CompanyInfoStateField = keyof CompanyInfoFields;

export interface CompanyInfoState extends CompanyInfoFields {
    companyNameValid?: boolean;
    companyNameTouched?: boolean;
    vatValid?: boolean;
    vatTouched?: boolean;
    vatTooltipDisplayed?: boolean;
    zipCodeValid?: boolean;
    zipCodeTouched?: boolean;
    streetValid?: boolean;
    streetTouched?: boolean;
    houseNoValid?: boolean;
    houseNoTouched?: boolean;
    neighborhoodValid?: boolean;
    neighborhoodTouched?: boolean;
    cityValid?: boolean;
    cityTouched?: boolean;
    cityIdValid?: boolean;
    phoneValid?: boolean;
    phoneTouched?: boolean;
    citySuggestions?: BrasilCity[];
    brazilCities?: object;
}

const initialState: CompanyInfoState = {
    countryCode: '',
    companyName: '',
    companyNameValid: false,
    companyNameTouched: false,
    vat: '',
    vatValid: false,
    vatTouched: false,
    vatTooltipDisplayed: false,
    taxEntity: TaxEntityType.VAT,
    zipCode: '',
    zipCodeValid: false,
    zipCodeTouched: false,
    street: '',
    streetValid: false,
    streetTouched: false,
    houseNo: '',
    houseNoValid: false,
    houseNoTouched: false,
    addressDetails: '',
    neighborhood: '',
    neighborhoodValid: false,
    neighborhoodTouched: false,
    city: '',
    cityValid: false,
    cityTouched: false,
    cityId: '',
    cityIdValid: false,
    state: '',
    phone: '',
    phoneValid: false,
    phoneTouched: false,
    citySuggestions: [],
    brazilCities: undefined,
};

const getBrasilCitySuggestions = (brazilCities: object | undefined, value: string): BrasilCity[] => {
    if (value && value.length > 0) {
        const firstLetter = value[0].toUpperCase();
        const brazilCitiesMatchingFirstLetter = (brazilCities as any)[firstLetter] || [];
        const suggestions = brazilCitiesMatchingFirstLetter.filter((city: any) => {
            return city.nome.toUpperCase().startsWith(value.toUpperCase());
        });
        return suggestions.sort((first: BrasilCity, second: BrasilCity) => {
            if (first.nome < second.nome) {
                return -1;
            }
            if (first.nome > second.nome) {
                return 1;
            }
            return 0;
        });
    }

    return [];
};

const getCitySuggestions = (brazilCities: object | undefined, countryCode: string, value: string) => {
    if (countryCode !== 'BR') {
        return [];
    } // only suggest for Brasil
    return getBrasilCitySuggestions(brazilCities, value);
};

const companyInfoSlice = createSlice({
    name: 'companyInfo',
    initialState,
    reducers: {
        displayVatToolTip: (state, action: PayloadAction<boolean>) => {
            state.vatTooltipDisplayed = action.payload;
        },
        setCompanyInfoField: (
            state,
            action: PayloadAction<{
                fieldName: CompanyInfoStateField;
                value: string;
            }>,
        ) => {
            const { fieldName, value } = action.payload;

            // update suggestions when city is changed
            const citySuggestions =
                fieldName === 'city' ? getCitySuggestions(state.brazilCities, state.countryCode, value) : [];

            const validProperty = `${fieldName}Valid`;
            const validator = getFieldValidator(fieldName, state);
            return {
                ...state,
                citySuggestions,
                [fieldName]: value,
                [validProperty]: validator(value),
            };
        },
        setCompanyInfoFields: (
            state,
            action: PayloadAction<{
                testCompanyInfo: CompanyInfoFields;
            }>,
        ) => {
            const { testCompanyInfo } = action.payload;
            state.countryCode = testCompanyInfo.countryCode;
            state.companyName = testCompanyInfo.companyName;
            state.vat = testCompanyInfo.vat;
            state.taxEntity = testCompanyInfo.taxEntity;
            state.zipCode = testCompanyInfo.zipCode;
            state.street = testCompanyInfo.street;
            state.houseNo = testCompanyInfo.houseNo;
            state.addressDetails = testCompanyInfo.addressDetails;
            state.neighborhood = testCompanyInfo.neighborhood;
            state.city = testCompanyInfo.city;
            state.cityId = testCompanyInfo.cityId;
            state.phone = testCompanyInfo.phone;
            state.state = testCompanyInfo.state;
        },
        setCityFields: (
            state,
            action: PayloadAction<{
                city: string;
                cityId: string;
                state: string;
            }>,
        ) => {
            const { city, cityId } = action.payload;
            const cityState = action.payload.state;

            const citySuggestions = getCitySuggestions(state.brazilCities, state.countryCode, city);
            const validatorFor = (field: CompanyInfoStateField) => getFieldValidator(field, state);
            state.citySuggestions = citySuggestions;
            state.city = city;
            state.cityValid = validatorFor('city')(city);
            state.cityId = cityId;
            state.cityIdValid = validatorFor('cityId')(city);
            state.state = cityState;
        },
        touchCompanyInfoField: (state, action: PayloadAction<keyof CompanyInfoState>) => {
            const fieldName = action.payload;
            const propertyName = `${fieldName}Touched`;
            return {
                ...state,
                [propertyName]: true,
            };
        },
        setTaxEntity: (state, action: PayloadAction<TaxEntityType>) => {
            state.taxEntity = action.payload;
        },
        clearCitySuggestions: (state) => {
            state.citySuggestions = [];
        },
        setBrazilCities: (state, action: PayloadAction<object>) => {
            state.brazilCities = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRegion, (state, action: PayloadAction<Region>) => {
            if (config.isTestTenantMode && action.payload === Region.latinamerica) {
                state.countryCode = 'BR';
                state.companyName = `Test company ${Math.random().toString(36).substring(2, 8)}`;
                state.vat = `${parseInt(String(Math.random() * 100000000000), 10)}`;
                state.taxEntity = TaxEntityType.NaturalEntity;
                state.zipCode = '11111';
                state.street = `Test street ${Math.random().toString(36).substring(2, 8)}`;
                state.houseNo = '11';
                state.addressDetails = '';
                state.neighborhood = 'Neighborhood';
                state.city = 'Abadia de Goiás';
                state.cityId = '5200050';
                state.phone = '';
                state.state = 'GO';
            } else if (config.isTestTenantMode && action.payload === Region.europe) {
                state.countryCode = 'DE';
                state.companyName = `Test company ${Math.random().toString(36).substring(2, 8)}`;
                state.vat = `TV${parseInt(String(Math.random() * 100000000), 10)}`;
                state.taxEntity = TaxEntityType.VAT;
                state.zipCode = '11111';
                state.street = `Teststreet ${Math.random().toString(36).substring(2, 8)}`;
                state.houseNo = '11';
                state.addressDetails = 'addressDetails';
                state.neighborhood = 'neighborhood';
                state.city = 'City';
                state.cityId = 'cityId';
                state.phone = '';
                state.state = 'State';
            }
        });
    },
});

export const {
    displayVatToolTip,
    setCompanyInfoField,
    setCompanyInfoFields,
    setCityFields,
    touchCompanyInfoField,
    setTaxEntity,
    clearCitySuggestions,
    setBrazilCities,
} = companyInfoSlice.actions;

export const geVatTooltipDisplayed = (state: RootState): boolean =>
    state.companyInfo.vatTooltipDisplayed !== undefined ? state.companyInfo.vatTooltipDisplayed : false;

export const companyInfoSelector = (state: RootState) => state.companyInfo;
export const companyInfoCountryCodeSelector = (state: RootState) => companyInfoSelector(state).countryCode;
export const selectTaxEntity = (state: RootState) => companyInfoSelector(state).taxEntity;
export const selectCitySuggestions = (state: RootState): BrasilCity[] => {
    if (state.companyInfo.citySuggestions !== undefined) {
        return state.companyInfo.citySuggestions;
    } else {
        return [];
    }
};

export const companyInfoReducer = companyInfoSlice.reducer;
