export const NlNL = () => {
    return (
        <div>
            <h3 className="popover-title">Wat is BTW en wat heeft het tot doel?</h3>
            <div className="popover-content">
                <p>
                    Ondernemingen, die producten of diensten verkopen, zijn hierover aan de overheid een belasting
                    verschuldigd. Door de verkoopprijs te belasten wordt de af te dragen belasting door de ondernemingen
                    verkregen.{' '}
                </p>
                <p>
                    De belasting wordt in Nederland als omzetbelasting of als belasting over toegevoegde waarde (BTW)
                    aangeduid. In andere landen, en in het bijzonder in engelstalige, is voor deze belasting het begrip
                    Value Added Tax (VAT) gebruikelijk.
                </p>
                <p>
                    Het BTW-nummer wordt automatisch van de belastingdienst verkregen, zodra een onderneming zich in
                    heeft laten schrijven bij de kamer van koophandel. Laat u informeren en registreer via{' '}
                    <a
                        href={
                            'https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/' +
                            'belastingdienst/zakelijk/ondernemen/onderneming_starten/' +
                            'schrijf_uw_bedrijf_in_bij_de_kamer_van_koophandel/schrijf_uw_onderneming_in'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Belastingdienst.nl
                    </a>
                </p>
            </div>
        </div>
    );
};
