import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appReducer from '../../features/app/appSlice';
import configReducer from './configSlice';
import langReducer from '../lang/langSlice';
import { regionReducer } from '../../features/region/regionSlice';
import { companyInfoReducer } from '../../features/companyInfo/companyInfoSlice';
import { personalInfoReducer } from '../../features/personal/personalInfoSlice';
import { registrationApi } from '../../services/registrationApiService';

const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    region: regionReducer,
    companyInfo: companyInfoReducer,
    personalInfo: personalInfoReducer,
    [registrationApi.reducerPath]: registrationApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(registrationApi.middleware),
        preloadedState,
    });

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type RootDispatch = AppStore['dispatch'];

export const store = setupStore();
