import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../configuration/setup/store';

export interface AppState {
    addAppStateHere: boolean;
}

const initialState: AppState = {
    addAppStateHere: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {},
});

// export const {} = appSlice.actions;

export const getExample = (state: RootState) => state.app.addAppStateHere;

export default appSlice.reducer;
