import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_ROUTE, isValidRoute } from './routes';
import { useAppNavigate } from '../../../configuration/setup/hooks';

const DefaultRedirect = () => {
    const { pathname } = useLocation();
    const navigate = useAppNavigate();

    useEffect(() => {
        if (!isValidRoute(pathname)) {
            navigate(DEFAULT_ROUTE);
        }
    }, [pathname, navigate]);

    return null;
};

export default DefaultRedirect;
