import { extractLanguage } from './lang/lang';
import { trace } from './setup/trace';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { getLocale } from './lang/langSlice';
import { configureFetchDisplayMessages } from './lang/services';

export const main = async (renderApp: () => void) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });
    // You will need to get the user language yourself then
    // you may fetch the suitable messages. Depending
    // on when and from where you fetch the user settings you might
    // want to employ a loading spinner while the request is ongoing.
    await fetchDisplayMessages();

    try {
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
