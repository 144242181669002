import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { store } from './configuration/setup/store';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import App from './features/app/App';
import { main } from './configuration';
import { createRoot } from 'react-dom/client';

const renderApplication = () => {
    const container = document.getElementById('root') as Element;
    const root = createRoot(container);

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <HashRouter>
                    <App />
                </HashRouter>
            </Provider>
        </ErrorBoundary>,
    );
};

main(renderApplication);
