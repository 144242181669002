import { TextInput } from './TextInput';

interface DisplayFieldProps {
    width: string;

    fieldLabel: string;
    fieldValue: string;
}

export const DisplayField = (props: DisplayFieldProps) => {
    return (
        <TextInput className={'col-xs-12 ' + props.width} disabled value={props.fieldValue} label={props.fieldLabel} />
    );
};
