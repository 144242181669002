import { FunctionComponent, ReactNode, useEffect } from 'react';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { AemLink } from './AemLink';
import { CustomSteppedProgress } from './CustomSteppedProgress';
import { AppNavigateFunction, useAppDispatch, useAppNavigate, useAppSelector } from '../../configuration/setup/hooks';
import { Region, regionSelector, setRegion } from '../region/regionSlice';
import { SelectRegion } from '../region/SelectRegion';
import { CompanyInfo } from '../companyInfo/CompanyInfo';
import { PersonalPage } from '../personal/PersonalPage';
import { Finished } from '../finished/Finished';
import { config } from '../../config';
import { isCompanyInfoValid } from '../companyInfo/companyInfo.validators';
import { RegistrationStep } from '../app/App';
import { DEFAULT_ROUTE, ROUTE_EUROPE, ROUTE_LATAM } from '../app/routes/routes';
import { ThunkDispatch } from '@reduxjs/toolkit';

interface Props {
    children?: ReactNode;
}

const Frame: FunctionComponent<Props> = (props) => (
    <div className={'display-flex justify-content-center ' + 'min-height-100vh ' + 'padding-right-25 padding-left-25'}>
        <div className={'display-flex flex-column align-self-center margin-10-sm width-100pct max-width-600'}>
            {config.isTestTenantMode ? (
                <div className={'col-xs-12 padding-bottom-25'}>
                    <ExpanderPanel title={'Test Tenant Mode'} bsStyle={'danger'}>
                        <p>This will create an account for testing purposes.</p>
                    </ExpanderPanel>
                </div>
            ) : null}
            {props.children}
        </div>
    </div>
);

interface FooterProps {
    step: string;
}

const Footer = ({ step }: FooterProps) => (
    <div className={'row'}>
        {step !== '/region' ? (
            <div className={'col-xs-7'}>
                <div className={'button-group'}>
                    <AemLink className={'btn btn-link padding-left-0'} aemKey={'imprintToplevel'} />
                    <AemLink className={'btn btn-link padding-left-0'} aemKey={'privacyToplevel'} />
                </div>
            </div>
        ) : (
            <div className={'col-xs-7'} />
        )}
        <div className={'col-xs-5 text-right'}>
            <img src={'https://cdn.rio.cloud/svg/common/ico_rio_powered.svg'} alt={'RIO Logo'} />
        </div>
    </div>
);

export const Registration = ({ step }: { step: RegistrationStep }) => {
    const { region, companyInfoIsValid } = useAppSelector((state) => ({
        companyInfoIsValid: isCompanyInfoValid(state),
        region: regionSelector(state),
    }));

    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        checkCorrectStep(step, companyInfoIsValid, region, navigate, dispatch);
    }, [step, companyInfoIsValid, region, navigate, dispatch]);

    const getStepComponent = (stepInt: RegistrationStep) => {
        switch (stepInt) {
            case 'finished':
                return <Finished />;
            case 'personal':
                return <PersonalPage />;
            case 'region':
                return <SelectRegion />;
            case 'europe':
            case 'latinamerica':
            default:
                return <CompanyInfo />;
        }
    };

    return (
        <Frame>
            <CustomSteppedProgress step={getStepNumber(step)} />
            {getStepComponent(step)}
            <Footer step={step} />
        </Frame>
    );
};

const getStepNumber = (step: RegistrationStep) => {
    switch (step) {
        case 'finished':
            return 3;
        case 'personal':
            return 2;
        case 'region':
            return 0;
        default:
            return 1;
    }
};

const checkCorrectStep = (
    step: RegistrationStep,
    companyInfoIsValid: boolean,
    region: Region | undefined,
    navigate: AppNavigateFunction,
    dispatch: ThunkDispatch<any, any, any>,
) => {
    if (step === 'region') {
        return;
    }
    if (region === undefined) {
        if (step === 'europe') {
            dispatch(setRegion(Region.europe));
            navigate(ROUTE_EUROPE);
        } else if (step === 'latinamerica') {
            dispatch(setRegion(Region.latinamerica));
            navigate(ROUTE_LATAM);
        } else {
            navigate(DEFAULT_ROUTE);
        }
    }
    if (step !== 'europe' && step !== 'latinamerica' && !companyInfoIsValid) {
        if (region === Region.europe) {
            navigate(ROUTE_EUROPE);
        } else if (region === Region.latinamerica) {
            navigate(ROUTE_LATAM);
        } else {
            navigate(DEFAULT_ROUTE);
        }
    }
};
