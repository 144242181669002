import { RootState } from '../../../configuration/setup/store';
import { setCompanyInfoField, touchCompanyInfoField } from '../companyInfoSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { isAppInMaintenanceMode } from '../../../configuration/tmpSlice';

export const mapStateToCompanyNameProps = (state: RootState) => ({
    fieldValue: state.companyInfo.companyName,
    fieldValid: state.companyInfo.companyNameValid,
    fieldTouched: state.companyInfo.companyNameTouched,
    fieldLabel: 'registration.companyData.companyName',
    lang: state.lang.displayLocale,
    name: 'companyName',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyNameProps = (dispatch: Dispatch) => ({
    setFieldValue: (newCompanyName: string) =>
        dispatch(setCompanyInfoField({ fieldName: 'companyName', value: newCompanyName })),
    touchField: () => dispatch(touchCompanyInfoField('companyName')),
});

export const mapStateToCompanyStreetProps = (state: RootState) => ({
    fieldTouched: state.companyInfo.streetTouched,
    fieldLabel: 'registration.companyData.street',
    fieldValid: state.companyInfo.streetValid,
    fieldValue: state.companyInfo.street,
    lang: state.lang.displayLocale,
    name: 'companyStreet',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyStreetProps = (dispatch: Dispatch) => ({
    setFieldValue: (newCompanyStreet: string) =>
        dispatch(setCompanyInfoField({ fieldName: 'street', value: newCompanyStreet })),
    touchField: () => dispatch(touchCompanyInfoField('street')),
});

export const mapStateToCompanyHouseNoProps = (state: RootState) => ({
    fieldTouched: state.companyInfo.houseNoTouched,
    fieldLabel: 'registration.companyData.houseNumber',
    fieldValid: state.companyInfo.houseNoValid,
    fieldValue: state.companyInfo.houseNo,
    lang: state.lang.displayLocale,
    name: 'companyHouseNo',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyHouseNoProps = (dispatch: Dispatch) => ({
    setFieldValue: (newHouseNumber: string) =>
        dispatch(setCompanyInfoField({ fieldName: 'houseNo', value: newHouseNumber })),
    touchField: () => dispatch(touchCompanyInfoField('houseNo')),
});

export const mapStateToCompanyZipCodeProps = (state: RootState) => ({
    fieldTouched: state.companyInfo.zipCodeTouched,
    fieldLabel: 'registration.companyData.zipCode',
    fieldValid: state.companyInfo.zipCodeValid,
    fieldValue: state.companyInfo.zipCode,
    lang: state.lang.displayLocale,
    error: state.companyInfo.zipCodeValid ? '' : 'registration.error.zipCode.invalid',
    name: 'companyZipCode',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyZipCodeProps = (dispatch: Dispatch) => ({
    setFieldValue: (newZipCode: string) => dispatch(setCompanyInfoField({ fieldName: 'zipCode', value: newZipCode })),
    touchField: () => dispatch(touchCompanyInfoField('zipCode')),
});

export const mapStateToCompanyCityProps = (state: RootState) => ({
    fieldTouched: state.companyInfo.cityTouched,
    fieldLabel: 'registration.companyData.city',
    fieldValid: state.companyInfo.cityValid,
    fieldValue: state.companyInfo.city,
    lang: state.lang.displayLocale,
    name: 'companyCity',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyCityProps = (dispatch: Dispatch) => ({
    setFieldValue: (newCity: string) => dispatch(setCompanyInfoField({ fieldName: 'city', value: newCity })),
    touchField: () => dispatch(touchCompanyInfoField('city')),
});

export const mapStateToCompanyNeighborhoodProps = (state: RootState) => ({
    fieldTouched: state.companyInfo.neighborhoodTouched,
    fieldLabel: 'registration.companyData.neighborhood',
    fieldValid: state.companyInfo.neighborhoodValid,
    fieldValue: state.companyInfo.neighborhood,
    lang: state.lang.displayLocale,
    name: 'companyNeighborhood',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyNeighborhoodProps = (dispatch: Dispatch) => ({
    setFieldValue: (newNeighborhood: string) =>
        dispatch(setCompanyInfoField({ fieldName: 'neighborhood', value: newNeighborhood })),
    touchField: () => dispatch(touchCompanyInfoField('neighborhood')),
});

export const mapStateToCompanyPhoneProps = (state: RootState) => ({
    fieldTouched: state.companyInfo.phoneTouched,
    fieldLabel: 'registration.companyData.phone',
    fieldValid: state.companyInfo.phoneValid,
    fieldValue: state.companyInfo.phone,
    fieldPlaceholder: '+55 21 3333 3333',
    lang: state.lang.displayLocale,
    error: state.companyInfo.phoneValid ? '' : 'registration.error.phoneNumber.InvalidFormat',
    showPopoverTooltip: true,
    popoverTooltipMessage: 'registration.tooltip.phoneNumber.validFormat',
    name: 'companyPhoneNumber',
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyPhoneProps = (dispatch: Dispatch) => ({
    setFieldValue: (newPhone: string) => dispatch(setCompanyInfoField({ fieldName: 'phone', value: newPhone })),
    touchField: () => dispatch(touchCompanyInfoField('phone')),
});

export const mapStateToCompanyStateProps = (state: RootState) => ({
    fieldLabel: 'registration.companyData.state',
    fieldValue: state.companyInfo.state,
    lang: state.lang.displayLocale,
});

export const mapStateToCompanyAddressDetailsProps = (state: RootState) => ({
    fieldTouched: false,
    fieldLabel: 'registration.companyData.addressDetails',
    fieldValid: true,
    fieldValue: state.companyInfo.addressDetails,
    lang: state.lang.displayLocale,
    fieldDisabled: isAppInMaintenanceMode(state),
});

export const mapDispatchToCompanyAddressDetailsProps = (dispatch: Dispatch) => ({
    setFieldValue: (newAddressDetails: string) =>
        dispatch(setCompanyInfoField({ fieldName: 'addressDetails', value: newAddressDetails })),
    touchField: () => {},
});
