import {
    cityValidator,
    companyNameValidator,
    houseNoValidator,
    notEmptyValidator,
    phoneNumberValidator,
    streetValidator,
    zipCodeValidator,
} from '../../components/Validator';
import { companyInfoSelector, CompanyInfoState, CompanyInfoStateField, TaxEntityType } from './companyInfoSlice';
import { Region, regionSelectorWithDefault } from '../region/regionSlice';
import { RootState } from '../../configuration/setup/store';

export const EuVatValidator = (vat: string): string | null => {
    if (!notEmptyValidator(vat)) {
        return 'registration.error.whitespaceOnly';
    }

    if (!/^[A-Za-z][A-Za-z][A-Za-z0-9]+$/g.test(vat)) {
        return 'registration.error.vatIdNumber.invalidFormat';
    }

    if (vat && vat.length > 15) {
        return 'registration.error.vatIdNumber.maxNumCharacters';
    }

    return null;
};

export const LatamVatValidator = (vat: string, taxEntity: TaxEntityType): string | null => {
    if (!notEmptyValidator(vat)) {
        return 'registration.error.whitespaceOnly';
    }
    switch (taxEntity) {
        case TaxEntityType.NaturalEntity:
            if (/[a-zA-Z]/g.test(vat)) {
                return 'registration.error.vatIdNumber.invalidFormatCPF';
            }
            // eslint-disable-next-line no-useless-escape
            if (vat.replace(/([\/\^\.\-\/])/g, '').length !== 11) {
                return 'registration.error.vatIdNumber.maxNumCharactersCPF';
            }
            break;
        case TaxEntityType.LegalEntity:
            if (/[a-zA-Z]/g.test(vat)) {
                return 'registration.error.vatIdNumber.invalidFormatCNPJ';
            }
            // eslint-disable-next-line no-useless-escape
            if (vat.replace(/([\/\^\.\-\/])/g, '').length !== 14) {
                return 'registration.error.vatIdNumber.maxNumCharactersCNPJ';
            }
    }
    return null;
};

export const getFieldValidator = (
    fieldName: CompanyInfoStateField,
    state: CompanyInfoState,
): ((value: any) => boolean) => {
    switch (fieldName) {
        case 'neighborhood':
        case 'cityId':
        case 'countryCode':
            return notEmptyValidator;
        case 'city':
            return cityValidator;
        case 'companyName':
            return companyNameValidator;
        case 'houseNo':
            return houseNoValidator;
        case 'street':
            return streetValidator;
        case 'zipCode':
            return zipCodeValidator;
        case 'phone':
            if (state.countryCode === 'BR') {
                return phoneNumberValidator;
            } else {
                return notEmptyValidator;
            }
        case 'vat':
            if (state.countryCode === 'BR') {
                return (value: string) => LatamVatValidator(value, state.taxEntity) === null;
            } else {
                return (value: string) => EuVatValidator(value) === null;
            }
        case 'taxEntity':
            return (value: TaxEntityType) => {
                return value !== TaxEntityType.VAT;
            };
        default:
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (value: any) => true;
    }
};

export const validateCountryCode = (companyInfoState: CompanyInfoState, region: Region) => {
    if (region === Region.europe) {
        return companyInfoState.countryCode !== 'BR';
    } else {
        return companyInfoState.countryCode === 'BR';
    }
};

export const isCompanyInfoValid = (state: RootState): boolean => {
    const region = regionSelectorWithDefault(state);
    const companyInfoState = companyInfoSelector(state);

    const requiredFields: CompanyInfoStateField[] =
        region === Region.latinamerica ? LATAM_REQUIRED_FIELDS : EUROPE_REQUIRED_FIELDS;

    const allRequiredFieldsValid = requiredFields.every((fieldName) => {
        const validator = getFieldValidator(fieldName, companyInfoState);
        return validator(companyInfoState[fieldName]);
    });

    const validCountryCode = validateCountryCode(companyInfoState, region);

    return allRequiredFieldsValid && validCountryCode;
};

const LATAM_REQUIRED_FIELDS: CompanyInfoStateField[] = [
    'neighborhood',
    'companyName',
    'houseNo',
    'phone',
    'street',
    'vat',
    'taxEntity',
    'zipCode',
    'city',
    'cityId',
];

const EUROPE_REQUIRED_FIELDS: CompanyInfoStateField[] = [
    'city',
    'companyName',
    'houseNo',
    'street',
    'vat',
    'zipCode',
    'countryCode',
];
