import { FormattedMessage } from 'react-intl';
import { selectTaxEntity, setTaxEntity, TaxEntityType } from '../companyInfoSlice';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { useDispatch } from 'react-redux';

export const TaxTypeRadioButton = () => {
    const dispatch = useDispatch();
    const { taxEntity } = useAppSelector((state) => ({ taxEntity: selectTaxEntity(state) }));
    return (
        <div className={'col-xs-12 col-sm-6 margin-top-5 margin-bottom-5'}>
            <div className={'form-group'}>
                <label className={'control-label'}>
                    <FormattedMessage id={'registration.companyData.taxType'} />
                </label>
                <div className={'position-relative row'}>
                    <div className={'col-xs-6'}>
                        <label className={'radio radio-inline'}>
                            <input
                                type={'radio'}
                                name={'taxType'}
                                id={'legalEntity'}
                                checked={taxEntity === TaxEntityType.LegalEntity}
                                onChange={() => dispatch(setTaxEntity(TaxEntityType.LegalEntity))}
                            />
                            <span className={'radio-text'}>
                                <FormattedMessage id={'registration.companyData.legalEntity'} />
                            </span>
                        </label>
                    </div>
                    <div className={'col-xs-6'}>
                        <label className={'radio radio-inline'}>
                            <input
                                type={'radio'}
                                name={'taxType'}
                                id={'naturalEntity'}
                                checked={taxEntity === TaxEntityType.NaturalEntity}
                                onChange={() => dispatch(setTaxEntity(TaxEntityType.NaturalEntity))}
                            />
                            <span className={'radio-text'}>
                                <FormattedMessage id={'registration.companyData.naturalEntity'} />
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
