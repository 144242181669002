import { AccountInformation1, AccountInformation2 } from '../AccountInformation';
import {
    CompanyCityContainer,
    CompanyHouseNoContainer,
    CompanyNameContainer,
    CompanyStreetContainer,
    CompanyZipCodeContainer,
} from '../elements/CompanyInfoElementContainers';
import { CountrySelect } from '../elements/CountrySelect';
import { VatField } from '../elements/VatField';
import { VatInfo } from '../elements/VatInfo';
import { EuVatValidator } from '../companyInfo.validators';
import { CompanyInfoNextButton } from '../elements/CompanyInfoNextButton';
import { AlreadyRegistered } from '../elements/AlreadyRegistered';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { Region, setRegion, regionSelector } from '../../region/regionSlice';

export const EuropeCompanyInfo = () => {
    const dispatch = useDispatch();
    const { region } = useAppSelector((state) => ({ region: regionSelector(state) }));
    useEffect(() => {
        if (region !== Region.europe) {
            dispatch(setRegion(Region.europe));
        }
    }, [dispatch, region]);

    return (
        <form className={'CompanyDataContainer'}>
            <VatInfo />
            <div className={'row'}>
                <div className={'flex-sm'}>
                    <CompanyNameContainer
                        width={'col-sm-6 margin-bottom-5'}
                        error={'registration.error.companyName'}
                        required
                    />
                    <VatField
                        validator={EuVatValidator}
                        vatLabel={'registration.companyData.vatIdNumber'}
                        required
                        showPopoverIcon
                    />
                </div>
                <div className={'flex-sm'}>
                    <CompanyStreetContainer
                        width={'col-sm-8 margin-top-10 margin-bottom-5'}
                        error={'registration.error.street'}
                        required
                    />
                    <CompanyHouseNoContainer
                        width={'col-sm-4 margin-top-10 margin-bottom-5'}
                        error={'registration.error.houseNo'}
                        required
                    />
                </div>
                <div className={'flex-sm'}>
                    <CompanyZipCodeContainer width={'col-sm-8 margin-top-10 margin-bottom-5'} required />
                    <CompanyCityContainer
                        width={'col-sm-8 margin-top-10 margin-bottom-5'}
                        error={'registration.error.city'}
                        required
                    />
                </div>
                <CountrySelect required />
                <div className={'form-group col-xs-12 margin-top-10'}>
                    <AccountInformation1 />
                    <AccountInformation2 />
                </div>
                <div className={'col-xs-12 margin-top-20-sm'}>
                    <div className={'row'}>
                        <AlreadyRegistered />
                        <CompanyInfoNextButton />
                    </div>
                </div>
            </div>
        </form>
    );
};
