import { TextInput } from './TextInput';

interface SimpleFieldProps {
    width: string;
    error?: string;
    required: boolean;
    showPopoverTooltip?: boolean;
    popoverTooltipMessage?: string;
    name?: string;

    fieldLabel: string;
    fieldValid: boolean | undefined;
    fieldTouched: boolean | undefined;
    fieldValue: string;
    fieldDisabled?: boolean;
    fieldPlaceholder?: string;

    setFieldValue(value: string): void;
    touchField(): void;
}

export const SimpleInputField = (props: SimpleFieldProps) => {
    return (
        <TextInput
            className={'col-xs-12 ' + props.width}
            value={props.fieldValue}
            label={props.fieldLabel}
            placeholder={props.fieldPlaceholder}
            name={props.name}
            popoverTooltip={props.showPopoverTooltip}
            popoverTooltipMessage={props.popoverTooltipMessage}
            disabled={props.fieldDisabled}
            required={props.required}
            onChange={(evt: any) => props.setFieldValue(evt.target.value)}
            error={props.fieldTouched && !props.fieldValid ? props.error : undefined}
            onBlur={(evt: any) => {
                props.setFieldValue(evt.target.value && evt.target.value.trim());
                props.touchField();
            }}
        />
    );
};

SimpleInputField.defaultProps = {
    width: '',
    fieldDisabled: false,
    required: true,
    error: 'registration.error.whitespaceOnly',
};
