import { FormattedMessage } from 'react-intl';
import { Region, regionSelector } from '../../region/regionSlice';
import { AemLink } from '../../registration/AemLink';
import { useAppSelector } from '../../../configuration/setup/hooks';

export const PersonalExplanation = () => {
    const { region } = useAppSelector((state) => ({
        region: regionSelector(state),
    }));

    return (
        <div className={'row margin-top-10'}>
            <div className={'col-xs-11  text-justify'}>
                <div className={'panel panel-default margin-bottom-0'}>
                    <div className={'panel-body'}>
                        <div className={'text-bold'}>
                            <FormattedMessage id={'registration.personalData.gtcApprove.link.rioPlatform'} />
                        </div>

                        <FormattedMessage
                            id={'registration.personalData.gtcApprove.v2'}
                            values={{
                                access: <AemLink aemKey={'access'} />,
                                conditions: <AemLink aemKey={'conditions'} />,
                                contract: <AemLink aemKey={'contract'} />,
                                privacy: <AemLink aemKey={'privacy'} />,
                            }}
                        />
                        <br />
                        <br />
                        {region !== Region.latinamerica ? (
                            <div>
                                <div className={'text-bold'}>
                                    <FormattedMessage id="registration.personalData.gtcApprove.link.serviceCareS" />
                                </div>
                                <div className={'margin-bottom-5'}>
                                    <FormattedMessage
                                        id={'registration.personalData.gtcApprove.serviceCareS.preamble'}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        id={'registration.personalData.gtcApprove.serviceCareS'}
                                        values={{
                                            specs: <AemLink aemKey={'serviceSpecsServiceCareS'} />,
                                            serviceTerms: <AemLink aemKey={'serviceTermsServiceCareS'} />,
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className={'text-bold'}>
                                    <FormattedMessage id={'registration.personalData.gtcApprove.link.essentials'} />
                                </div>
                                <FormattedMessage
                                    id={'registration.personalData.gtcApprove.essentials'}
                                    values={{
                                        specs: <AemLink aemKey={'serviceSpecs'} />,
                                        serviceTerms: <AemLink aemKey={'serviceTerms'} />,
                                    }}
                                />
                            </div>
                        )}
                        {region === Region.latinamerica ? (
                            <div>
                                <br />
                                <i>
                                    <FormattedMessage id={'registration.personalData.gtcApprove.technicalDetails'} />
                                </i>
                                <br />
                                <br />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
