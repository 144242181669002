export const NlBE = () => {
    return (
        <div>
            <h3 className="popover-title">Wat is de btw nummer, en wat is het doel?</h3>
            <div className="popover-content">
                <p>
                    Belasting over de toegevoegde waarde is een belasting geheven door de overheid op de verkoop van
                    goederen en diensten. Alle bedrijven met een jaaromzet van meer dan de huidige btw-drempel moeten
                    zich registreren voor btw en een driemaandelijkse btw-aangifte voltooien. Hoe te registreren De
                    meeste bedrijven kunnen zich online registreren. Door dit te doen, registreert u voor een btw nummer
                    en creëert u een online BTW-account (ook wel een &quot;Government Gateway-account&quot; genoemd).
                </p>
                <p>
                    <a href="https://ec.europa.eu/commission/index_en" target="_blank" rel="noreferrer">
                        https://ec.europa.eu/commission/index_en
                    </a>
                </p>
            </div>
        </div>
    );
};
