import { connect } from 'react-redux';
import { SimpleInputField } from '../../../components/SimpleInputField';
import {
    mapDispatchToNewsletterApprovedProps,
    mapDispatchToPersonalEmailProps,
    mapDispatchToPersonalPhoneNumberProps,
    mapDispatchToTermsAcceptedProps,
    mapStateToNewsletterApprovedProps,
    mapStateToPersonalEmailProps,
    mapStateToPersonalPhoneNumberProps,
    mapStateToTermsAcceptedProps,
} from './personalElement';
import { Checkbox } from '../../../components/Checkbox';

export const PersonalEmailContainer = connect(
    mapStateToPersonalEmailProps,
    mapDispatchToPersonalEmailProps,
)(SimpleInputField);

export const PersonalPhoneNumberContainer = connect(
    mapStateToPersonalPhoneNumberProps,
    mapDispatchToPersonalPhoneNumberProps,
)(SimpleInputField);

export const TermsAcceptedContainer = connect(mapStateToTermsAcceptedProps, mapDispatchToTermsAcceptedProps)(Checkbox);

export const NewsletterApprovedContainer = connect(
    mapStateToNewsletterApprovedProps,
    mapDispatchToNewsletterApprovedProps,
)(Checkbox);
