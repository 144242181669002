export const PtPT = () => {
    return (
        <div>
            <h3 className="popover-title">O é o IVA e qual o seu propósito?</h3>
            <div className="popover-content">
                <p>
                    O imposto sobre valor agregado é um imposto cobrado pelo governo sobre vendas de bens e serviços.
                    Todas as empresas que tenham um volume de negócios anual superior ao limite atual de IVA -
                    atualmente 85.000€ - devem registrar-se e efectuar uma declaração de IVA trimestral.{' '}
                </p>
                <p>
                    <b>Como registrar</b>
                </p>
                <p>
                    A maioria das empresas pode registrar-se online. Ao realizar este procedimento, cria uma conta
                    online de IVA (
                    <a href="https://www.nif.pt" target="_blank" rel="noreferrer">
                        https://www.nif.pt
                    </a>{' '}
                    |
                    <a href="http://vat-search.eu/" target="_blank" rel="noreferrer">
                        http://vat-search.eu/
                    </a>
                    ).
                </p>
            </div>
        </div>
    );
};
