import './App.css';

import { FormattedMessage, IntlProvider } from 'react-intl';
import { Routes, Route } from 'react-router-dom';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { DEFAULT_ROUTE, ROUTE_EUROPE, ROUTE_FINISHED, ROUTE_LATAM, ROUTE_PERSONAL } from './routes/routes';
import DefaultRedirect from './routes/DefaultRedirect';

import { Registration } from '../registration/Registration';
import { isAppInMaintenanceMode } from '../../configuration/tmpSlice';

const App = () => {
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const maintenanceMode = useAppSelector(isAppInMaintenanceMode);

    if (!displayMessages || !userLocale) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'UserRegistration'}>
                <ApplicationLayout.Body>
                    {maintenanceMode ? (
                        <ApplicationLayoutBodyBanner>
                            <FormattedMessage id={'maintenanceMode.message'} />
                        </ApplicationLayoutBodyBanner>
                    ) : null}
                    <NotificationsContainer />
                    <Routes>
                        <Route path={DEFAULT_ROUTE} element={<RouteFactory step={'region'} />} />
                        <Route path={ROUTE_EUROPE} element={<RouteFactory step={'europe'} />} />
                        <Route path={ROUTE_LATAM} element={<RouteFactory step={'latinamerica'} />} />
                        <Route path={ROUTE_PERSONAL} element={<RouteFactory step={'personal'} />} />
                        <Route path={ROUTE_FINISHED} element={<RouteFactory step={'finished'} />} />
                        <Route path={'*'} element={<DefaultRedirect />} />
                    </Routes>
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export type RegistrationStep = 'region' | 'europe' | 'latinamerica' | 'personal' | 'finished';

const RouteFactory = ({ step }: { step: RegistrationStep }) => <Registration step={step} />;

export default App;
