export const EnGB = () => {
    return (
        <div>
            <h3 className="popover-title">What is the VAT and what is the purpose?</h3>
            <div className="popover-content">
                <p>
                    Value added tax is a tax levied by the government on sales of goods and services. All businesses
                    which have an annual turnover of more than the current VAT threshold – currently £85,000 – must
                    register for VAT and complete a quarterly VAT return.
                </p>
                <p>
                    <b>How to register:</b>
                </p>
                <p>
                    Most businesses can register{' '}
                    <a href="https://www.access.service.gov.uk/login/signin/creds" target="_blank" rel="noreferrer">
                        online
                    </a>
                    . By doing this you will register for VAT and create a VAT online account (sometimes known as a
                    “Government Gateway account”).
                </p>
            </div>
        </div>
    );
};
