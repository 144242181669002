import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

interface LooseObject {
    [key: string]: ReactNode;
}

const renderHighlights = (baseTextId: string, highlightKeys: string[], highlightClass: string) => {
    const messageHighlightValues: LooseObject = {};

    highlightKeys.forEach((highlightKey: string) => {
        messageHighlightValues[highlightKey] = (
            <span className={highlightClass}>
                <FormattedMessage id={`${baseTextId}.${highlightKey}`} />
            </span>
        );
    });

    return messageHighlightValues;
};

export const HighlightedMessage = (props: any) => (
    <span className={'HighlightedMessage'}>
        <FormattedMessage
            id={props.textId}
            values={renderHighlights(props.textId, props.highlightKeys || [], props.highlightClass)}
        />
    </span>
);
