export const DeDE = () => {
    return (
        <div>
            <h3 className="popover-title">Was ist eine USt-IdNr. und wofür wird sie benötigt?</h3>
            <div className="popover-content word-break">
                <p>
                    Unternehmen die Güter oder Dienstleistungen verkaufen, müssen Steuern an den Staat entrichten –
                    dabei werden alle Leistungen, Waren oder Dienste besteuert, die ein Unternehmen gegen Bezahlung
                    erbringt.{' '}
                </p>
                <p>
                    Diese Steuer wird in Deutschland als Umsatzsteuer oder Mehrwertsteuer bezeichnet. In anderen Ländern
                    und besonders im englischsprachigen Raum ist dafür vor allem der Begriff Value Added Tax (VAT)
                    gebräuchlich.
                </p>
                <p>
                    Das Formular für die{' '}
                    <a href="https://www.bzst.de/DE/Home/home_node.html" target="_blank" rel="noreferrer">
                        Online-Beantragung
                    </a>{' '}
                    der Ust-IdNr. erfolgt über den Link und anschließend unter Online Dienste.
                </p>
            </div>
        </div>
    );
};
