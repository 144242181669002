export const FrFR = () => {
    return (
        <div>
            <h3 className="popover-title">Qu’est-ce que la TVA et dans quel but?</h3>
            <div className="popover-content word-break">
                <p>
                    La taxe sur la valeur ajoutée est une taxe prélevée par le gouvernement sur les ventes de biens et
                    de services. Toutes les entreprises soumises à l&#39;IS doivent télé-déclarer leur TVA en ligne sans
                    condition de chiffre d&#39;affaires minimal.{' '}
                </p>
                <p>Comment s&#39;inscrire</p>
                <p>Rendez-vous sur le site des impôts à l’adresse suivante :</p>
                <p>
                    <a href="https://www.impots.gouv.fr/portail/professionnel/tva" target="_blank" rel="noreferrer">
                        https://www.impots.gouv.fr/portail/professionnel/tva
                    </a>
                </p>
                <p>
                    Ou remplir la déclaration 3310 CA3 (ainsi que ses annexes éventuelles), disponible sous le numéro
                    Cerfa 10963*21
                </p>
            </div>
        </div>
    );
};
