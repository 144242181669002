import { TextInput } from '../../../components/TextInput';
import { displayVatToolTip, setCompanyInfoField, TaxEntityType, touchCompanyInfoField } from '../companyInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';

interface VatFieldProps {
    validator(vat: string, taxEntity?: TaxEntityType): any;
    vatLabel: string;
    required: boolean;
    showPopoverIcon: boolean;
    className?: string;
    disabled?: boolean;
}

export const VatField = (props: VatFieldProps) => {
    const { vat, vatTouched, entityType, showVatPopover, fieldName } = useAppSelector((state) => ({
        vat: state.companyInfo.vat,
        vatTouched: state.companyInfo.vatTouched,
        entityType: state.companyInfo.taxEntity,
        showVatPopover:
            state.companyInfo.vatTooltipDisplayed === undefined ? false : state.companyInfo.vatTooltipDisplayed,
        fieldName: 'companyVat',
    }));
    const dispatch = useAppDispatch();
    return (
        <TextInput
            className={props.className ? `col-xs-12 col-sm-6 ${props.className}` : 'col-xs-12 col-sm-6'}
            disabled={props.disabled}
            value={vat}
            label={props.vatLabel}
            tooltip={props.showPopoverIcon}
            required={props.required}
            name={fieldName}
            onTooltipClick={() => dispatch(displayVatToolTip(!showVatPopover))}
            onChange={(evt: any) => dispatch(setCompanyInfoField({ fieldName: 'vat', value: evt.target.value }))}
            onBlur={() => dispatch(touchCompanyInfoField('vat'))}
            error={vatTouched ? props.validator(vat, entityType) : null}
        />
    );
};
