import { FormattedMessage } from 'react-intl';
import { config } from '../../../config';

export const AlreadyRegistered = () => {
    return (
        <div className={'col-xs-12 col-sm-6'}>
            <div className={'line-height-small'}>
                <FormattedMessage id={'registration.companyData.alreadyRegistered'} />
            </div>
            <a href={config.homeRoute}>
                <FormattedMessage id={'registration.loginNow'} />
            </a>
        </div>
    );
};
