const getListItem = (index: number, step: number) => {
    const symbol = index === 3 ? <i className={'rioglyph rioglyph-ok'} /> : index;

    return (
        <li className={`step-tab ${index === step ? 'active' : 'disabled'}`} role={'presentation'}>
            <a href={'#'} role={'button'} style={{ pointerEvents: 'none' }} tabIndex={-1}>
                <div className={'item-icon text-center'}>{symbol}</div>
            </a>
        </li>
    );
};

export const CustomSteppedProgress = ({ step = 1 }: { step: number }) => {
    return (
        <ul className={'stepped-progress-bar nav variant-circle'}>
            {getListItem(1, step)}
            {getListItem(2, step)}
            {getListItem(3, step)}
        </ul>
    );
};
