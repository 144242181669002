import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFieldValidator } from './personal.validators';
import { setRegion } from '../region/regionSlice';
import { config } from '../../config';

export type PersonalInfoField = keyof PersonalInfoState;

export type PersonalToggles = keyof PersonalCheckBoxes;

// TODO: clean up the interfaces
export interface PersonalCheckBoxes {
    newsletterApproved: boolean;
    termsAccepted: boolean;
    showPassword?: boolean;
}

export interface PersonalInfoState {
    firstName: string;
    firstNameValid?: boolean;
    firstNameTouched?: boolean;
    lastName: string;
    lastNameValid?: boolean;
    lastNameTouched?: boolean;
    email: string;
    emailValid?: boolean;
    emailTouched?: boolean;
    phoneNumber?: string;
    phoneNumberValid?: boolean;
    phoneNumberTouched?: boolean;
    password: string;
    passwordValid?: boolean;
    passwordTouched?: boolean;
    newsletterApproved: boolean;
    termsAccepted: boolean;
    showPassword: boolean;
}

const initialState: PersonalInfoState = {
    firstName: '',
    firstNameValid: false,
    firstNameTouched: false,
    lastName: '',
    lastNameValid: false,
    lastNameTouched: false,
    email: '',
    emailValid: false,
    emailTouched: false,
    phoneNumber: '',
    phoneNumberValid: true,
    phoneNumberTouched: false,
    password: '',
    passwordValid: false,
    passwordTouched: false,
    showPassword: false,
    newsletterApproved: false,
    termsAccepted: false,
};

const personalInfoSlice = createSlice({
    name: 'personal',
    initialState,
    reducers: {
        setPersonalInfoField: (state, action: PayloadAction<{ fieldName: PersonalInfoField; value: string }>) => {
            const { fieldName, value } = action.payload;
            const nextState = {
                ...state,
                [fieldName]: value,
            };

            const validProperty = `${fieldName}Valid`;
            const validator = getFieldValidator(fieldName) || (() => true);
            return {
                ...nextState,
                [validProperty]: validator(value),
            };
        },
        touchPersonalInfoField: (state, action: PayloadAction<PersonalInfoField>) => {
            const fieldName = action.payload;

            // we dynamically add a "[fieldName]Touched" property with value true
            // which is otherwise "undefined" and because of that false
            const propertyName = `${fieldName}Touched`;

            return {
                ...state,
                [propertyName]: true,
            };
        },
        togglePersonalCheckbox: (state, action: PayloadAction<PersonalToggles>) => {
            const fieldName = action.payload;

            return {
                ...state,
                [fieldName]: !state[fieldName],
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setRegion, (state) => {
            if (config.isTestTenantMode) {
                state.firstName = 'TestUserFirstName';
                state.firstNameValid = true;
                state.lastName = 'TestUserLastName';
                state.lastNameValid = true;
            }
        });
    },
});

export const { setPersonalInfoField, touchPersonalInfoField, togglePersonalCheckbox } = personalInfoSlice.actions;

export const personalInfoReducer = personalInfoSlice.reducer;
