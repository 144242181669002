import { HighlightedMessage } from '../../registration/LooseObject';

export const PasswordCriteria = () => (
    <div>
        <p>
            <i>
                <HighlightedMessage
                    textId={'registration.personalData.pwTextCriteria'}
                    highlightClass={'text-bold'}
                    highlightKeys={['numCharacters', 'upperCase', 'lowerCase', 'number', 'specialCharacter']}
                />
            </i>
        </p>
    </div>
);
