import { FormattedMessage } from 'react-intl';
import './style/css/regionselection.css';
import worldmapEurope from '../../assets/worldmap_europe.svg';
import worldmapAmericas from '../../assets/worldmap_americas.svg';
import worldmapKorea from '../../assets/worldmap_korea.svg';
import worldmapPlaceholder from '../../assets/worldmap_placeholder.png';
import { Region, regionSelector, setRegion } from './regionSlice';
import { useAppDispatch, useAppNavigate, useAppSelector } from '../../configuration/setup/hooks';
import { config } from '../../config';
import { ROUTE_EUROPE, ROUTE_LATAM } from '../app/routes/routes';
import { isAppInMaintenanceMode } from '../../configuration/tmpSlice';

const CDN_URL = 'https://cdn.rio.cloud/images/worldmap';

const images = {
    worldmapPlaceHolder: `${CDN_URL}/worldmap_placeholder.png`,
    worldmapEurope: `${CDN_URL}/worldmap_europe.svg`,
    worldmapBrasil: `${CDN_URL}/worldmap_brasil.svg`,
};

const inputStyleEurope = {
    width: '18%',
    height: '25%',
    left: '40%',
    top: '5%',
};

const inputStyleBrasil = {
    width: '15%',
    height: '30%',
    left: '19%',
    top: '52%',
};

const inputStyleKorea = {
    width: '2%',
    height: '4%',
    left: '85.5%',
    top: '28%',
};

export const getTranslatedRegion = (region: Region) => {
    if (region === Region.korea) {
        return <FormattedMessage id={'registration.region.korea'} />;
    } else if (region === Region.latinamerica) {
        return <FormattedMessage id={'registration.region.latinamerica'} />;
    } else {
        return <FormattedMessage id={'registration.region.europe'} />;
    }
};

export const SelectRegion = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { region, maintenanceMode } = useAppSelector((state) => ({
        region: regionSelector(state),
        maintenanceMode: isAppInMaintenanceMode(state),
    }));
    return (
        <div className={'RegistrationPanelContainer panel-default panel panel-body padding-25'}>
            <h2 className={'text-center margin-bottom-25'}>
                <FormattedMessage id={'registration.regionSelection.heading'} />
            </h2>
            {config.isTestTenantMode ? (
                <>
                    <div className={'RegionSelectionContainer'}>
                        <div className={'region-selection'}>
                            <input
                                type={'radio'}
                                className={'map-area'}
                                id={'area-europe'}
                                checked={region === Region.europe}
                                style={inputStyleEurope}
                                onChange={() => dispatch(setRegion(Region.europe))}
                            />
                            <input
                                type={'radio'}
                                className={'map-area'}
                                id={'area-americas'}
                                checked={region === Region.latinamerica}
                                style={inputStyleBrasil}
                                onChange={() => dispatch(setRegion(Region.latinamerica))}
                            />
                            <input
                                type={'radio'}
                                className={'map-area'}
                                id={'area-korea'}
                                checked={region === Region.korea}
                                style={inputStyleKorea}
                                onChange={() => dispatch(setRegion(Region.korea))}
                            />
                            <img
                                id={'worldmap-europe'}
                                className={'worldmap'}
                                src={worldmapEurope}
                                alt={'Europe map'}
                            />
                            <img
                                id={'worldmap-americas'}
                                className={'worldmap'}
                                src={worldmapAmericas}
                                alt={'Americas map'}
                            />
                            <img id={'worldmap-korea'} className={'worldmap'} src={worldmapKorea} alt={'Korea map'} />
                            <img
                                id={'worldmap'}
                                className={'img-responsive'}
                                src={worldmapPlaceholder}
                                alt={'World map'}
                            />
                        </div>
                    </div>
                    <div className={'display-flex align-items-center justify-content-center padding-10 min-height-100'}>
                        {region === Region.korea ? (
                            <div className={'text-center max-width-400 alert alert-info margin-bottom-0'}>
                                <FormattedMessage id={'registration.korea.infoMessage'} />
                            </div>
                        ) : (
                            <button
                                onClick={() => {
                                    navigate(region === Region.latinamerica ? ROUTE_LATAM : ROUTE_EUROPE);
                                }}
                                className={'btn btn-primary btn-icon-right'}
                                disabled={!region || maintenanceMode}
                            >
                                {!region ? (
                                    <FormattedMessage id={'registration.regionSelection.buttonLabel'} />
                                ) : (
                                    <FormattedMessage
                                        id={'registration.regionSelection.buttonLabel.SelectedLanguage'}
                                        values={{
                                            Region: getTranslatedRegion(region),
                                        }}
                                    />
                                )}
                            </button>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div className={'RegionSelectionContainer'}>
                        <div className={'region-selection'}>
                            <input
                                type={'radio'}
                                className={'map-area'}
                                id={'area-europe'}
                                checked={region === Region.europe}
                                style={inputStyleEurope}
                                onChange={() => dispatch(setRegion(Region.europe))}
                                aria-label={'Europe'}
                            />
                            <input
                                type={'radio'}
                                className={'map-area'}
                                id={'area-brasil'}
                                checked={region === Region.latinamerica}
                                style={inputStyleBrasil}
                                onChange={() => dispatch(setRegion(Region.latinamerica))}
                                aria-label={'Brazil'}
                            />
                            <img
                                id={'worldmap-europe'}
                                className={'worldmap'}
                                src={images.worldmapEurope}
                                alt={'Europe map'}
                            />
                            <img
                                id={'worldmap-brasil'}
                                className={'worldmap'}
                                src={images.worldmapBrasil}
                                alt={'Brasil map'}
                            />
                            <img
                                id={'worldmap_old'}
                                className={'img-responsive'}
                                src={images.worldmapPlaceHolder}
                                alt={'World map'}
                            />
                        </div>
                    </div>
                    <div className={'text-center padding-top-25'}>
                        <button
                            onClick={() => {
                                navigate(region === Region.latinamerica ? ROUTE_LATAM : ROUTE_EUROPE);
                            }}
                            className={'btn btn-primary btn-icon-right'}
                            disabled={!region || maintenanceMode}
                        >
                            <FormattedMessage id={'registration.regionSelection.buttonLabel'} />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
