import { VatField } from '../elements/VatField';
import { LatamVatValidator } from '../companyInfo.validators';
import {
    CompanyAddressDetailsContainer,
    CompanyHouseNoContainer,
    CompanyNameContainer,
    CompanyNeighborhoodContainer,
    CompanyPhoneContainer,
    CompanyStateContainer,
    CompanyStreetContainer,
    CompanyZipCodeContainer,
} from '../elements/CompanyInfoElementContainers';
import { AccountInformation1, AccountInformation2 } from '../AccountInformation';
import { AlreadyRegistered } from '../elements/AlreadyRegistered';
import { CompanyInfoNextButton } from '../elements/CompanyInfoNextButton';
import { TaxTypeRadioButton } from '../elements/TaxTypeRadioButton';
import { LatamCitySelect } from '../elements/LatamCitySelect';
import { DummyCountrySelectLatam } from '../elements/DummyCountrySelectLatam';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { companyInfoCountryCodeSelector, setBrazilCities, setCompanyInfoField } from '../companyInfoSlice';
import { Region, regionSelector, setRegion } from '../../region/regionSlice';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { isAppInMaintenanceMode } from '../../../configuration/tmpSlice';

const loadBrazilCityList = (dispatch: Dispatch) => {
    import('../../../brasil-city-list.json').then(({ default: brazilCities }) => {
        dispatch(setBrazilCities(brazilCities));
    });
};

const setBrCountryCode = (dispatch: Dispatch) => {
    dispatch(setCompanyInfoField({ fieldName: 'countryCode', value: 'BR' }));
};

export const LatamCompanyInfo = () => {
    const dispatch = useDispatch();
    const { region, countryCode, disabled } = useAppSelector((state) => ({
        region: regionSelector(state),
        countryCode: companyInfoCountryCodeSelector(state),
        disabled: isAppInMaintenanceMode(state),
    }));
    useEffect(() => {
        loadBrazilCityList(dispatch);
        if (region !== Region.latinamerica || countryCode !== 'BR') {
            dispatch(setRegion(Region.latinamerica));
            setBrCountryCode(dispatch);
        }
    }, [dispatch, region, countryCode]);

    return (
        <form className={'CompanyDataContainer'}>
            <div className={'row'}>
                <div className={'flex-sm'}>
                    <CompanyNameContainer
                        width={'margin-bottom-10'}
                        error={'registration.error.companyName'}
                        required
                    />
                </div>
                <div className={'flex-sm'}>
                    <TaxTypeRadioButton />
                    <VatField
                        validator={LatamVatValidator}
                        vatLabel={'registration.companyData.cnpjcpf'}
                        showPopoverIcon={false}
                        className={'margin-top-5 margin-bottom-5'}
                        required
                    />
                </div>
                <div className={'flex-sm'}>
                    <CompanyZipCodeContainer width={'col-sm-8 margin-bottom-5'} required />
                    <CompanyStreetContainer
                        width={'col-sm-8 margin-top-5 margin-bottom-5'}
                        error={'registration.error.street'}
                        required
                    />
                </div>
                <div className={'flex-sm'}>
                    <CompanyHouseNoContainer
                        width={'col-sm-4 margin-top-5 margin-bottom-5'}
                        error={'registration.error.houseNo'}
                        required
                    />
                    <CompanyAddressDetailsContainer width={'col-sm-4 margin-top-5 margin-bottom-5'} required={false} />
                    <CompanyNeighborhoodContainer width={'col-sm-4 margin-top-5 margin-bottom-5'} required />
                </div>
                <div className={'flex-sm'}>
                    <LatamCitySelect width={'col-sm-4 margin-top-5 margin-bottom-5'} required />
                    <CompanyStateContainer width={'col-sm-2 margin-top-5 margin-bottom-5'} />
                    <CompanyPhoneContainer width={'col-sm-6 margin-top-5 margin-bottom-5'} required />
                </div>
                <DummyCountrySelectLatam disabled={disabled} />
                <div className={'form-group col-xs-12'}>
                    <AccountInformation1 />
                    <AccountInformation2 />
                </div>
                <div className={'col-xs-12 margin-top-20-sm'}>
                    <div className={'row'}>
                        <AlreadyRegistered />
                        <CompanyInfoNextButton />
                    </div>
                </div>
            </div>
        </form>
    );
};
