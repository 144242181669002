import { FormattedMessage } from 'react-intl';

import { makeUrl } from './aemLink';
import { regionSelectorWithDefault } from '../region/regionSlice';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getLocale } from '../../configuration/lang/langSlice';

const intlPath = (key: string) => `registration.personalData.gtcApprove.link.${key}`;

interface AemLinkProps {
    aemKey: string;
    className?: string;
}

export const AemLink = (props: AemLinkProps) => {
    const { lang, region } = useAppSelector((state) => ({
        lang: getLocale(state),
        region: regionSelectorWithDefault(state),
    }));
    const { aemKey, className } = props;
    const intlId = intlPath(aemKey);
    const url = makeUrl(aemKey, lang, region);

    return (
        <a className={className} href={url} target={'_blank'} rel={'noreferrer'}>
            <FormattedMessage id={intlId} />
        </a>
    );
};
