export const EsES = () => {
    return (
        <div>
            <h3 className="popover-title">Proceso solicitud vat</h3>
            <div className="popover-content word-break">
                <p>
                    Se puede solicitar el VAT simplemente por ser facturado por una empresa intracomunitaria que te
                    presta un servicio.
                </p>
                <p>
                    Se solicita o bien presencialmente o por la página WEB{' '}
                    <a href="https://www.agenciatributaria.es" target="_blank" rel="noreferrer">
                        https://www.agenciatributaria.es
                    </a>{' '}
                    Teléfono de cita previa (atención personal): 901 22 33 44 y 91 553 00 71 (de L a V, de 9 a 19h).
                    Entregando el Modelo 036.
                </p>
                <a
                    href="https://www.agenciatributaria.gob.es/AEAT.sede/procedimientos/G322.shtml"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://www.agenciatributaria.gob.es/AEAT.sede/procedimientos/G322.shtml
                </a>
                <p>Información tributaria básica: 91 554 87 70 (opción 3)</p>
            </div>
        </div>
    );
};
