import packageJson from '../package.json';

export interface ConfigState {
    backend: {
        REGISTRATION_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
    isTestTenantMode: boolean;
    serviceVersion: string;
    serviceEnvironment: string;
}

const {
    location: { host },
} = window;

export const config: ConfigState = {
    backend: {
        REGISTRATION_SERVICE: import.meta.env.VITE_REGISTRATION_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'userRegistration',
    isTestTenantMode: host === 'test-registration.iam.rio.cloud' || host === '127.0.0.1:9089',
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
};
