export const resolveExamplePhoneNumber = (countryCode: string) => {
    switch (countryCode) {
        case 'DE':
            return '+49 89 901820';
        case 'AT':
            return '+43 1 512 3457';
        case 'IT':
            return '+39 312 1234567';
        case 'PL':
            return '+48 22 1234567';
        case 'FR':
            return '+33 1 09 75 83 51';
        case 'CZ':
            return '+420 602 123 456';
        case 'ES':
            return '+34 12 345 678';
        case 'BR':
            return '+55 21 3333 3333';
        default:
            return '+49 89 901820';
    }
};
