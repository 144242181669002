import { useAppNavigate, useAppSelector } from '../../../configuration/setup/hooks';
import { FormattedMessage } from 'react-intl';
import { isCompanyInfoValid } from '../companyInfo.validators';
import { ROUTE_PERSONAL } from '../../app/routes/routes';

export const CompanyInfoNextButton = () => {
    const { isValid } = useAppSelector((state) => ({
        isValid: isCompanyInfoValid(state),
    }));
    const navigate = useAppNavigate();
    return (
        <div className={'col-xs-12 col-sm-6 text-right'}>
            <button
                disabled={!isValid}
                onClick={(e) => {
                    e.preventDefault();
                    navigate(ROUTE_PERSONAL);
                }}
                className={'loginSubmitButton btn btn-primary btn-icon-right'}
            >
                <span className={'rioglyph rioglyph-chevron-right'} />
                <FormattedMessage id={'registration.companyData.nextButtonLabel'} />
            </button>
        </div>
    );
};
