import { firstAndLastNameValidator, Validator } from '../../components/Validator';
import { PersonalInfoField, PersonalInfoState } from './personalInfoSlice';

const emailValidator = (value: string) =>
    /^[\x21-\x26\x28-\x7E]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
        value,
    ) && value.length <= 73;

// country code +89x is not a valid country code
const optionalPhoneNumberValidator = (value: string) =>
    /^$|^\+[1-9][0-9 ]{5,20}$/.test(value) && !value.startsWith('+89') && !/\s{2,}/.test(value);

export const nameValidator = (value: string) => {
    const errors = [];

    if (!value || value.trim().length === 0) {
        errors.push('registration.error.name.WhitespaceOnly');
    } else if (!!value && value.length > 40) {
        errors.push('registration.error.name.maxNumCharacters');
    } else if (!firstAndLastNameValidator(value)) {
        errors.push('registration.error.name.notAllowedFormat');
    }
    return errors;
};

export const passwordValidator = (value: string) => {
    // Cognito allowed special characters: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -
    // See https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html

    const errors = [];
    if (!value || value.length < 10) {
        errors.push('registration.error.pw.minNumCharacters');
    }

    if (!!value && value.length > 127) {
        errors.push('registration.error.pw.maxNumCharacters');
    }

    // allowed characters
    if (/[^a-zA-Z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/g.test(value)) {
        errors.push('registration.error.pw.allowedCharacters');
    }

    // at least one small character
    if (!value || !/[a-z]/g.test(value)) {
        errors.push('registration.error.pw.lowercase');
    }

    // at least one big character
    if (!/[A-Z]/g.test(value)) {
        errors.push('registration.error.pw.uppercase');
    }

    // at least one number
    if (!/[0-9]/g.test(value)) {
        errors.push('registration.error.pw.number');
    }

    // at least one special character
    if (!/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/g.test(value)) {
        errors.push('registration.error.pw.specialCharacter');
    }

    return errors;
};

const isSelectedValidator = (value: boolean | null | undefined): boolean => !!value;

export const getFieldValidator = (fieldName: PersonalInfoField): Validator<any> | undefined => {
    switch (fieldName) {
        case 'lastName':
            return firstAndLastNameValidator;
        case 'firstName':
            return firstAndLastNameValidator;
        case 'email':
            return emailValidator;
        case 'phoneNumber':
            return optionalPhoneNumberValidator;
        case 'termsAccepted':
            return isSelectedValidator;
        case 'password':
            return (value) => passwordValidator(value).length === 0;
        default:
            return undefined;
    }
};

export const isPersonalInfoValid = (state: PersonalInfoState): boolean =>
    !!state.firstNameValid &&
    !!state.lastNameValid &&
    !!state.emailValid &&
    !!state.phoneNumberValid &&
    !!state.passwordValid &&
    state.termsAccepted;
