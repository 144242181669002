export type Validator<T> = (value: T) => boolean;

export const notEmptyValidator: Validator<string> = (value: string) => !!value && /\S+/g.test(value);

export const zipCodeValidator: Validator<string> = (zipCode: string) => notEmptyAndLengthValidator(zipCode, 20);

export const phoneNumberValidator: Validator<string> = (phoneNumber: string) =>
    notEmptyValidator(phoneNumber) && /^\+[1-9][0-9 ]{5,20}$/g.test(phoneNumber) && !/\s{2,}/.test(phoneNumber);

export const firstAndLastNameValidator: Validator<string> = (value: string) =>
    notEmptyAndLengthValidator(value, 40) && /^[^!$^#*&/"<>|\\{}=+?%]*$/g.test(value);

export const companyNameValidator: Validator<string> = (companyName: string) =>
    notEmptyAndLengthValidator(companyName, 100);

// street + houseNo together can only contain at most 254 characters since both get concatenated with one space
// in-between as first line of company address which has to be <= 255 chars
export const streetValidator: Validator<string> = (street: string) => notEmptyAndLengthValidator(street, 234);

export const houseNoValidator: Validator<string> = (houseNo: string) => notEmptyAndLengthValidator(houseNo, 20);

export const cityValidator: Validator<string> = (city: string) => notEmptyAndLengthValidator(city, 40);

const notEmptyAndLengthValidator: (value: string, maxLength: number) => boolean = (
    value: string,
    maxLength: number,
) => {
    return notEmptyValidator(value) && value.length <= maxLength;
};
