import { FormattedMessage } from 'react-intl';
import { useAppNavigate, useAppSelector } from '../../../configuration/setup/hooks';
import { AppRoute, ROUTE_EUROPE, ROUTE_LATAM } from '../../app/routes/routes';
import { Region, regionSelector } from '../../region/regionSlice';

export const PersonalBackButton = ({ isLoading }: { isLoading: boolean }) => {
    const navigate = useAppNavigate();
    const { region } = useAppSelector((state) => ({
        region: regionSelector(state),
    }));
    const navigationTarget: AppRoute = region === Region.latinamerica ? ROUTE_LATAM : ROUTE_EUROPE;

    return (
        <div className={'col-xs-6'}>
            <button
                disabled={isLoading}
                onClick={(e) => {
                    e.preventDefault();
                    navigate(navigationTarget);
                }}
                className={'btn btn-primary'}
            >
                <span className={'rioglyph rioglyph-chevron-left'} />
                <FormattedMessage id={'registration.personalData.backButtonLabel'} />
            </button>
        </div>
    );
};
