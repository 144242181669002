import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import UiKitCheckbox from '@rio-cloud/rio-uikit/Checkbox';
import { MouseEvent, PropsWithChildren } from 'react';

interface CheckboxProps {
    value: boolean;
    disabled?: boolean;
    className?: string;
    messageId: string;
    error?: string;
    onClick: (event: MouseEvent) => void;
}

export const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
    const { value, disabled, className, messageId, onClick, error } = props;

    const cs = {
        disabled,
        'form-group': true,
        'has-error has-feedback': error,
    };

    return (
        <div className={classNames(className)}>
            <div className={classNames(cs)}>
                <UiKitCheckbox checked={value} disabled={disabled} onClick={onClick}>
                    <FormattedMessage id={messageId} />
                    {props.children}
                </UiKitCheckbox>
            </div>
        </div>
    );
};
