import { connect } from 'react-redux';
import {
    mapDispatchToCompanyAddressDetailsProps,
    mapDispatchToCompanyCityProps,
    mapDispatchToCompanyHouseNoProps,
    mapDispatchToCompanyNameProps,
    mapDispatchToCompanyNeighborhoodProps,
    mapDispatchToCompanyPhoneProps,
    mapDispatchToCompanyStreetProps,
    mapDispatchToCompanyZipCodeProps,
    mapStateToCompanyAddressDetailsProps,
    mapStateToCompanyCityProps,
    mapStateToCompanyHouseNoProps,
    mapStateToCompanyNameProps,
    mapStateToCompanyNeighborhoodProps,
    mapStateToCompanyPhoneProps,
    mapStateToCompanyStateProps,
    mapStateToCompanyStreetProps,
    mapStateToCompanyZipCodeProps,
} from './companyInfoElement';
import { SimpleInputField } from '../../../components/SimpleInputField';
import { DisplayField } from '../../../components/DisplayField';

export const CompanyNameContainer = connect(
    mapStateToCompanyNameProps,
    mapDispatchToCompanyNameProps
)(SimpleInputField);

export const CompanyStreetContainer = connect(
    mapStateToCompanyStreetProps,
    mapDispatchToCompanyStreetProps
)(SimpleInputField);

export const CompanyHouseNoContainer = connect(
    mapStateToCompanyHouseNoProps,
    mapDispatchToCompanyHouseNoProps
)(SimpleInputField);

export const CompanyZipCodeContainer = connect(
    mapStateToCompanyZipCodeProps,
    mapDispatchToCompanyZipCodeProps
)(SimpleInputField);

export const CompanyCityContainer = connect(
    mapStateToCompanyCityProps,
    mapDispatchToCompanyCityProps
)(SimpleInputField);

export const CompanyNeighborhoodContainer = connect(
    mapStateToCompanyNeighborhoodProps,
    mapDispatchToCompanyNeighborhoodProps
)(SimpleInputField);

export const CompanyPhoneContainer = connect(
    mapStateToCompanyPhoneProps,
    mapDispatchToCompanyPhoneProps
)(SimpleInputField);

export const CompanyStateContainer = connect(mapStateToCompanyStateProps)(DisplayField);

export const CompanyAddressDetailsContainer = connect(
    mapStateToCompanyAddressDetailsProps,
    mapDispatchToCompanyAddressDetailsProps
)(SimpleInputField);
