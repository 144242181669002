import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ListMenu from '@rio-cloud/rio-uikit/ListMenu';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { CsCZ } from '../../vatPopoverComponents/cs-CZ';
import { DeCH } from '../../vatPopoverComponents/de-CH';
import { DeDE } from '../../vatPopoverComponents/de-DE';
import { EnGB } from '../../vatPopoverComponents/en-GB';
import { EsES } from '../../vatPopoverComponents/es-ES';
import { FrFR } from '../../vatPopoverComponents/fr-FR';
import { HuHU } from '../../vatPopoverComponents/hu-HU';
import { ItIT } from '../../vatPopoverComponents/it-IT';
import { NlBE } from '../../vatPopoverComponents/nl-BE';
import { NlNL } from '../../vatPopoverComponents/nl-NL';
import { PlPL } from '../../vatPopoverComponents/pl-PL';
import { PtPT } from '../../vatPopoverComponents/pt-PT';
import { SkSK } from '../../vatPopoverComponents/sk-SK';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { displayVatToolTip, geVatTooltipDisplayed } from '../companyInfoSlice';

const supportedCountryMap: { [s: string]: any } = {
    CZ: <CsCZ />,
    CH: <DeCH />,
    DE: <DeDE />,
    GB: <EnGB />,
    ES: <EsES />,
    FR: <FrFR />,
    HU: <HuHU />,
    IT: <ItIT />,
    BE: <NlBE />,
    NL: <NlNL />,
    PL: <PlPL />,
    PT: <PtPT />,
    SK: <SkSK />,
};

const getRightContent = (country: string): any => {
    return supportedCountryMap[country] ? supportedCountryMap[country] : <EnGB />;
};

const getNavItem = (switchCountry: (arg: string) => void, selectedCountry: string, country: string) => ({
    item: (
        <a onClick={() => switchCountry(country)} className={selectedCountry === country ? 'active' : ''}>
            {<FormattedMessage id={`registration.countrycode.${country}`} />}
        </a>
    ),
    key: country,
});

const getCountries = (switchCountry: (arg: string) => void, selectedCountry: string) => [
    {
        navItems: Object.keys(supportedCountryMap).map((country) =>
            getNavItem(switchCountry, selectedCountry, country),
        ),
    },
];

const getLeftContent = (switchCountry: (arg: string) => void, selectedCountry: string) => {
    const countries = getCountries(switchCountry, selectedCountry);

    return <ListMenu key={selectedCountry} menuItems={countries} groupClassName={'padding-left-0'} />;
};

export const VatInfo = () => {
    const [selectedCountry, setSelectedCountry] = useState('GB');
    const dispatch = useAppDispatch();
    const { showVatPopover } = useAppSelector((state) => ({ showVatPopover: geVatTooltipDisplayed(state) }));
    const switchCountry = (country: string) => {
        setSelectedCountry(country);
    };
    const displayVatTooltip = (displayVatTooltipValue: boolean) => {
        dispatch(displayVatToolTip(displayVatTooltipValue));
    };
    return (
        <div>
            <SplitDialog
                show={showVatPopover}
                title={<FormattedMessage id={'registration.companyData.vatIdNumber'} />}
                leftContent={getLeftContent(switchCountry, selectedCountry)}
                rightContent={getRightContent(selectedCountry)}
                onClose={() => displayVatTooltip(false)}
                bsSize={'lg'}
            />
        </div>
    );
};
