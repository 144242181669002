import { FormattedMessage, useIntl } from 'react-intl';
import Select from '@rio-cloud/rio-uikit/Select';
import { getAvailableCountries, transformToTranslatableOptions } from '../../availableCountries';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { companyInfoCountryCodeSelector, setCompanyInfoField } from '../companyInfoSlice';
import { isAppInMaintenanceMode } from '../../../configuration/tmpSlice';

const filterLatamCountries = (countryCodes: string[]) => countryCodes.filter((countryCode) => countryCode !== 'BR');

export const CountrySelect = (props: { required?: boolean }) => {
    const { disabled, availableCountries, countryCode } = useAppSelector((state) => ({
        countryCode: companyInfoCountryCodeSelector(state),
        availableCountries: filterLatamCountries(getAvailableCountries()),
        disabled: isAppInMaintenanceMode(state),
    }));
    const dispatch = useAppDispatch();
    const intl = useIntl();

    return (
        <div className={'form-group'}>
            <div className={'col-xs-12 margin-top-10 margin-bottom-5'}>
                <div className={'form-group'}>
                    <label className={'control-label'}>
                        <FormattedMessage id={'registration.companyData.countryCode'} />
                        {props.required ? '*' : null}
                    </label>
                    <Select
                        disabled={disabled}
                        placeholder={''}
                        options={transformToTranslatableOptions(availableCountries, countryCode, intl)}
                        onChange={(item: any) =>
                            dispatch(setCompanyInfoField({ fieldName: 'countryCode', value: item.id }))
                        }
                    />
                </div>
            </div>
        </div>
    );
};
