export const getAvailableCountries = () => AVAILABLE_COUNTRIES;

const EU_28 = [
    'BE', // Belgien
    'BG', // Bulgarien
    'DK', // Dänemark
    'DE', // Deutschland
    'EE', // Estland
    'FI', // Finnland
    'FR', // Frankreich
    'GR', // Griechenland
    'IE', // Irland
    'IT', // Italien
    'HR', // Kroatien
    'LV', // Lettland
    'LT', // Litauen
    'LU', // Luxemburg
    'MT', // Malta
    'NL', // Niederlande
    'AT', // Österreich
    'PL', // Polen
    'PT', // Portugal
    'RO', // Rumänien
    'SE', // Schweden
    'SK', // Slowakei
    'SI', // Slowenien
    'ES', // Spanien
    'CZ', // Tschechien
    'HU', // Ungarn
    'GB', // Vereinigtes Königreich
    'CY', // Zypern
];

const EU_ADDITIONAL = [
    'NO', // Norwegen
    'IS', // Island
    'CH', // Schweiz
    'LI', // Liechtenstein
];

const LATAM = [
    'BR', // Brasilien
];

const AVAILABLE_COUNTRIES = [...EU_28, ...EU_ADDITIONAL, ...LATAM];

export const transformToTranslatableOptions = (keys: string[], selectedCode: string, intl: any): any =>
    keys
        .map((key) => {
            const keyName = `registration.countrycode.${key}`;
            const translated = intl.formatMessage({ id: keyName });
            return {
                id: key,
                label: translated,
                selected: selectedCode === key,
            };
        })
        .sort((a, b) => {
            if (a.label === b.label) {
                return 0;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 1;
        });
