import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import { HighlightedMessage } from '../registration/LooseObject';
import { useAppSelector } from '../../configuration/setup/hooks';
import { config } from '../../config';
import { useEffect } from 'react';

const FinishedHeader = () => (
    <h2 className={'text-center margin-bottom-20'}>
        <FormattedMessage id={'registration.activateAccount.heading'} />
    </h2>
);

const EmailSentConfirmation = () => {
    const { email } = useAppSelector((state) => ({ email: state.personalInfo.email }));
    useEffect(() => {
        Notification.success(
            <FormattedMessage
                id={'request.finished.successfully'}
                defaultMessage={'Your account has been successfully created.'}
            />,
        );
    }, []);
    return (
        <div className={'form-group col-xs-12'}>
            <p>
                <HighlightedMessage
                    textId={'registration.activateAccount.text1'}
                    highlightClass={'text-bold'}
                    highlightKeys={['activationLink']}
                />
            </p>
            <p className={'text-size-largest'}>{email}</p>
            <p>
                <FormattedMessage id={'registration.activateAccount.text2'} />
            </p>
        </div>
    );
};

const EmailHelp = () => (
    <div className={'form-group col-xs-12'}>
        <ExpanderPanel title={<FormattedMessage id={'registration.activateAccount.noMail'} />} bsStyle={'default'}>
            <ul>
                <li>
                    <span>
                        <FormattedMessage id={'registration.activateAccount.checkSpamFolder'} />
                    </span>
                </li>
                <li>
                    <span>
                        <FormattedMessage id={'registration.activateAccount.checkEmailValid'} />
                    </span>
                </li>
            </ul>
        </ExpanderPanel>
    </div>
);

const LoginNowField = () => (
    <div className={'col-xs-12 margin-top-20-sm text-right'}>
        <a className={'btn btn-primary'} href={config.homeRoute}>
            <FormattedMessage id={'registration.loginNow'} />
        </a>
    </div>
);

export const Finished = () => (
    <div className={'panel-default panel panel-body padding-25'}>
        <FinishedHeader />
        <div className={'row'}>
            <EmailSentConfirmation />
            <EmailHelp />
            <LoginNowField />
        </div>
    </div>
);
