import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, RootDispatch } from './store';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { AppRoute } from '../../features/app/routes/routes';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<RootDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppNavigateFunction = (route: AppRoute, options?: NavigateOptions) => void;
export const useAppNavigate = (): AppNavigateFunction => {
    const navigate = useNavigate();
    return (route: AppRoute, options?: NavigateOptions) => navigate(route, options);
};
