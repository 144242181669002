import classNames from 'classnames';
import { Fragment, ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputFieldTooltip } from './InputFieldTooltip';

const toArray = (inp: string | string[] | undefined) => {
    if (!inp) {
        return [];
    }

    if (Array.isArray(inp)) {
        return inp;
    }

    return [inp];
};

interface TextInputProps {
    label: string;
    disabled?: boolean;
    value?: string;
    className?: string;
    error?: string | string[];
    warning?: string;
    success?: string;
    inputType?: string;
    tooltip?: boolean;
    placeholder?: string;
    popoverTooltip?: boolean;
    popoverTooltipMessage?: string;
    required?: boolean;
    name?: string;
    showDisplayPasswordToggle?: boolean;

    onChange?(event: any): void;

    onBlur?(event: any): void;

    onFocus?(event: any): void;

    onTooltipClick?(event: any): void;
}

export const TextInput = (props: TextInputProps) => {
    const {
        value,
        label,
        disabled,
        className,
        inputType,
        tooltip,
        popoverTooltip,
        required,
        onChange,
        onBlur,
        onFocus,
        onTooltipClick,
        popoverTooltipMessage,
        name,
        showDisplayPasswordToggle,
        placeholder: rawPlaceholder,
    } = props;

    const intl = useIntl();

    let placeholder: string | undefined;

    if (rawPlaceholder?.startsWith('registration.')) {
        placeholder = intl.formatMessage({ id: rawPlaceholder });
    } else {
        placeholder = rawPlaceholder;
    }

    const [icon, setIcon] = useState('rioglyph-eye-option');
    const [toggledInputType, setToggledInputType] = useState(inputType);

    const toggleInputType = () => {
        if (toggledInputType === 'password') {
            setToggledInputType('text');
            setIcon('rioglyph-eye-close');
        } else if (toggledInputType === 'text') {
            setToggledInputType('password');
            setIcon('rioglyph-eye-option');
        }
    };

    const error = toArray(props.error);
    const warning = toArray(props.warning);
    const success = toArray(props.success);

    const cs = {
        'form-group': true,
        'has-error has-feedback': error.length > 0,
        'has-warning has-feedback': warning.length > 0,
        'has-success has-feedback': success.length > 0,
    };

    // quick fix for not showing passwords in error logs
    const successMsgs = [...success]
        .filter((msg) => msg && msg.length > 0)
        .reduce((acc: ReactNode[], val: ReactNode) => {
            acc.push(val);
            acc.push(<br />);
            return acc;
        }, []);

    // combine messages
    const otherMsgs: ReactNode[] = [...warning, ...error]
        .filter((msg) => msg && msg.length > 0)
        .map((msg, id) => <FormattedMessage id={msg} key={id} />)
        .reduce((acc: ReactNode[], val: ReactNode) => {
            acc.push(val);
            acc.push(<br />);
            return acc;
        }, []);

    const msgs = successMsgs.concat(otherMsgs).map((node, id) => <Fragment key={id}>{node}</Fragment>);

    return (
        <div className={classNames({ [className as any]: true })}>
            <div className={classNames(cs)}>
                <label className={'control-label display-block position-relative'}>
                    <FormattedMessage id={label} />
                    {required ? '*' : null}
                    {tooltip ? (
                        <span
                            onClick={(evt) => {
                                if (onTooltipClick) {
                                    onTooltipClick(evt);
                                }
                            }}
                            className={
                                'rioglyph rioglyph-question-sign cursor-pointer margin-left-5 text-size-16 text-muted'
                            }
                        />
                    ) : null}
                    {popoverTooltip && popoverTooltipMessage && (
                        <InputFieldTooltip popoverTooltipMessage={popoverTooltipMessage} trigger={'click'} />
                    )}
                </label>
                <div className="position-relative">
                    <input
                        className={'form-control'}
                        autoComplete={
                            name === 'personalPassword'
                                ? 'new-password'
                                : name === 'personalEmail'
                                  ? 'username'
                                  : undefined
                        }
                        type={toggledInputType || 'text'}
                        value={value || ''}
                        disabled={disabled}
                        name={name}
                        placeholder={placeholder}
                        onChange={(evt) => {
                            if (onChange) {
                                onChange(evt);
                            }
                        }}
                        onBlur={(evt) => {
                            if (onBlur) {
                                onBlur(evt);
                            }
                        }}
                        onFocus={(evt) => {
                            if (onFocus) {
                                onFocus(evt);
                            }
                        }}
                    />
                    {showDisplayPasswordToggle && value && value.length > 0 && (
                        <span
                            className={`rioglyph ${icon} position-absolute right-10 top-50pct translate-y-50pct cursor-pointer text-color-dark`}
                            onClick={toggleInputType}
                        />
                    )}
                    {error.length > 0 || warning.length > 0 ? (
                        <span
                            className={`form-control-feedback rioglyph rioglyph-warning-sign position-absolute top-50pct translate-y-50pct ${
                                showDisplayPasswordToggle && value && value.length > 0 ? 'right-25' : 'right-10'
                            }`}
                        />
                    ) : null}
                </div>
                {msgs.length > 0 ? <div className={'help-block'}>{msgs}</div> : null}
            </div>
        </div>
    );
};
