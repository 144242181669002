export const DeCH = () => {
    return (
        <div>
            <h3 className="popover-title">Unternehmens-Identifikationsnummer (UID)</h3>
            <div className="popover-content">
                <p>
                    Jedes in der Schweiz aktive Unternehmen erhält eine einheitliche Unternehmens-Identifikationsnummer
                    (UID). Zur korrekten Zuteilung, Verwaltung und Verwendung der UID führt das BFS das UID-Register.
                </p>
                <p>
                    Weitere Informationen zur UID finden Sie hier:{' '}
                    <a
                        href={
                            'https://www.bfs.admin.ch/bfs/de/home/register/unternehmensregister/' +
                            'unternehmens-identifikationsnummer.html'
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        Unternehmens-Identifikationsnummer
                    </a>
                </p>
            </div>
        </div>
    );
};
