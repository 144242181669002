export const HuHU = () => {
    return (
        <div>
            <h3 className="popover-title">Mi az ÁFA és mi a célja?</h3>
            <div className="popover-content">
                <p>
                    Az ÁFA egy olyan adó, amelyet az állam szab ki termékek és szolgáltatások értékesítése után. Minden
                    vállalkozás, amely terméket vagy szolgáltatást értékesít köteles az ÁFA megfizetésére. Az ÁFA
                    összege negyedévente visszaigényelhető.{' '}
                </p>
                <p>
                    <b>Hogyan regisztrálhat?</b>
                </p>
                <p>
                    A legtöbb vállalkozás regisztrálható online. Ezzel a lépéssel regisztrál az ÁFA rendszerbe és
                    létrehoz egy online ÁFA fiókot. (néha ,,kormányzati átjáróként” nevesített rendszer)
                </p>
                <p style={{ wordBreak: 'break-all' }}>
                    <a
                        href="https://nav.gov.hu/nav/adatbazisok/adatbleker/afaalanyok/afaalanyok_egyszeru"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://nav.gov.hu/nav/adatbazisok/adatbleker/afaalanyok/afaalanyok_egyszeru
                    </a>
                </p>
            </div>
        </div>
    );
};
