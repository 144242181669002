import qs, { IParseOptions, IStringifyOptions } from 'qs';

export const DEFAULT_ROUTE = '/region';
export const ROUTE_EUROPE = '/europe';
export const ROUTE_LATAM = '/latinamerica';
export const ROUTE_PERSONAL = '/personal';
export const ROUTE_FINISHED = '/finished';

const routes = [DEFAULT_ROUTE, ROUTE_EUROPE, ROUTE_LATAM, ROUTE_PERSONAL, ROUTE_FINISHED] as const;
export type AppRoute = typeof routes[number];

export const isValidRoute = (maybeRoute: unknown): boolean => {
    const route = routes.find((validName) => validName === maybeRoute);
    return !!route;
};

export const STRINGIFY_OPTIONS: IStringifyOptions = {
    // required to stringify arrays into comma separated strings
    arrayFormat: 'comma',
    // don't use array indices
    indices: false,
    // don't encode the entire string as it will be done individually for certain params
    encode: false,
    // required to remove empty params
    skipNulls: true,
};

export const PARSE_OPTIONS: IParseOptions = {
    // required to parse comma separated string into array
    comma: true,
};

export interface RouteState {
    myId: string | undefined;
    anotherId: string | undefined;
}

export const parseRoute = (locationSearch: string): RouteState => {
    const decodedSearch = decodeURIComponent(locationSearch.replace('?', ''));
    const { myId, anotherId } = qs.parse(decodedSearch, PARSE_OPTIONS);
    return {
        myId,
        anotherId,
        // add other url params and convert them to the right type here
        // in order to easily work with them in the RouteUpdater component
    } as RouteState;
};

export const makeRoute = (searchParams: RouteState): string => {
    const queryParams = qs.stringify(searchParams, STRINGIFY_OPTIONS);
    const searchFragment = queryParams && `?${queryParams}`;
    return encodeURI(searchFragment);
};
