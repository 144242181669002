import { HighlightedMessage } from '../registration/LooseObject';

export const AccountInformation1 = () => {
    return (
        <p>
            <HighlightedMessage
                textId={'registration.accountInformation.text1'}
                highlightClass={'text-bold'}
                highlightKeys={['firstUser', 'addEmployees']}
            />
        </p>
    );
};

export const AccountInformation2 = () => {
    return (
        <p>
            <HighlightedMessage
                textId={'registration.accountInformation.text2'}
                highlightClass={'text-bold'}
                highlightKeys={['beAdvised']}
            />
        </p>
    );
};
