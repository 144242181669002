import { Dispatch } from 'redux';
import { RootState } from '../../../configuration/setup/store';
import { setPersonalInfoField, togglePersonalCheckbox, touchPersonalInfoField } from '../personalInfoSlice';
import { resolveExamplePhoneNumber } from './personalElementsUtils';

export const mapStateToPersonalFirstNameProps = (state: RootState) => {
    return {
        fieldValue: state.personalInfo.firstName,
        fieldValid: !!state.personalInfo.firstNameValid,
        fieldTouched: !!state.personalInfo.firstNameTouched,
        fieldLabel: 'registration.personalData.firstName',
        name: 'personalFirstName',
    };
};

export const mapDispatchToPersonalFirstNameProps = (dispatch: Dispatch) => ({
    setFieldValue: (newFirstName: string) =>
        dispatch(setPersonalInfoField({ fieldName: 'firstName', value: newFirstName })),
    touchField: () => dispatch(touchPersonalInfoField('firstName')),
});

export const mapStateToPersonalLastNameProps = (state: RootState) => ({
    fieldValue: state.personalInfo.lastName,
    fieldValid: !!state.personalInfo.lastNameValid,
    fieldTouched: !!state.personalInfo.lastNameTouched,
    fieldLabel: 'registration.personalData.lastName',
    name: 'personalLastName',
});

export const mapDispatchToPersonalLastNameProps = (dispatch: Dispatch) => ({
    setFieldValue: (newLastName: string) =>
        dispatch(setPersonalInfoField({ fieldName: 'lastName', value: newLastName })),
    touchField: () => dispatch(touchPersonalInfoField('lastName')),
});

export const mapStateToPersonalEmailProps = (state: RootState) => ({
    fieldValue: state.personalInfo.email,
    fieldValid: state.personalInfo.emailValid,
    fieldTouched: state.personalInfo.emailTouched,
    fieldLabel: 'registration.personalData.email',
    fieldPlaceholder: 'example@domain.com',
    name: 'personalEmail',
    popoverTooltipMessage: 'registration.tooltip.email.validFormat',
    showPopoverTooltip: true,
});

export const mapStateToPersonalPhoneNumberProps = (state: RootState) => ({
    fieldValue: state.personalInfo.phoneNumber ? state.personalInfo.phoneNumber : '',
    fieldValid: state.personalInfo.phoneNumberValid,
    fieldTouched: state.personalInfo.phoneNumberTouched,
    fieldLabel: 'registration.personalData.phoneNumber',
    fieldPlaceholder: resolveExamplePhoneNumber(state.companyInfo.countryCode),
    error: state.personalInfo.phoneNumberValid ? '' : 'registration.error.phoneNumber.InvalidFormat',
    showPopoverTooltip: true,
    popoverTooltipMessage: 'registration.tooltip.phoneNumber.validFormat',
    name: 'personalPhoneNumber',
});

export const mapDispatchToPersonalEmailProps = (dispatch: Dispatch) => ({
    setFieldValue: (newEmail: string) => dispatch(setPersonalInfoField({ fieldName: 'email', value: newEmail })),
    touchField: () => dispatch(touchPersonalInfoField('email')),
});

export const mapDispatchToPersonalPhoneNumberProps = (dispatch: Dispatch) => ({
    setFieldValue: (newPhoneNumber: string) =>
        dispatch(setPersonalInfoField({ fieldName: 'phoneNumber', value: newPhoneNumber })),
    touchField: () => dispatch(touchPersonalInfoField('phoneNumber')),
});

export const mapStateToTermsAcceptedProps = (state: RootState) => ({
    value: state.personalInfo.termsAccepted,
    messageId: 'registration.personalData.gtcApprove.checkbox',
});

export const mapDispatchToTermsAcceptedProps = (dispatch: Dispatch) => ({
    onClick: () => dispatch(togglePersonalCheckbox('termsAccepted')),
});

export const mapStateToNewsletterApprovedProps = (state: RootState) => ({
    value: state.personalInfo.newsletterApproved,
    messageId: 'registration.personalData.newsletterApproved',
});

export const mapDispatchToNewsletterApprovedProps = (dispatch: Dispatch) => ({
    onClick: () => dispatch(togglePersonalCheckbox('newsletterApproved')),
});
