import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';

export enum Region {
    europe = 'europe',
    latinamerica = 'latinamerica',
    korea = 'korea',
}

export interface RegionState {
    region: Region | undefined;
}

const initialState: RegionState = { region: undefined };

const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegion: (state, action: PayloadAction<Region>) => {
            state.region = action.payload;
        },
    },
});

export const { setRegion } = regionSlice.actions;

export const regionSelector = (state: RootState) => state.region.region;

export const regionSelectorWithDefault = (state: RootState) =>
    state.region.region !== undefined ? state.region.region : Region.europe;

export const regionReducer = regionSlice.reducer;
