import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

interface Props {
    popoverTooltipMessage: string;
    className?: string;
    trigger?: 'hover' | 'click' | 'focus';
    placement?: 'right' | 'left' | 'top' | 'bottom';
}

export const InputFieldTooltip: FunctionComponent<Props> = (props) => {
    const intl = useIntl();
    return (
        <OverlayTrigger
            placement={props.placement}
            trigger={props.trigger}
            overlay={
                <Tooltip id={'tooltip'} className={props.placement}>
                    {intl.formatMessage({ id: props.popoverTooltipMessage })}
                </Tooltip>
            }
        >
            <span
                className={classNames(
                    'rioglyph rioglyph-question-sign margin-left-5 text-size-16 text-muted',
                    props.className,
                )}
                aria-hidden={'true'}
            />
        </OverlayTrigger>
    );
};
