export const CsCZ = () => {
    return (
        <div>
            <h3 className="popover-title">Daň z přidané hodnoty (zkratka DPH)</h3>
            <div className="popover-content">
                <p>
                    Platí ji všichni při nákupu většiny zboží a služeb, proto se jí také někdy říká univerzální daň nebo
                    též všestranná daň. Princip této <a href="https://cs.wikipedia.org/wiki/Da%C5%88"> daně </a> je v
                    tom, že dodavatel, pokud je registrován jako plátce, musí odvést z obchodu část hodnoty, pokud je
                    tento obchod předmětem daně. Naopak odběratel si za jistých podmínek může zažádat o vrácení daně,
                    kterou při obchodu dodavateli (plátci) zaplatil. Registrovat k DPH se lze dobrovolně nebo povinně. A
                    to překročením stanoveného obratu nebo například zakoupením zboží určité hodnoty. Další možností je
                    přijetí i poskytnutí služby do jiného členského státu.
                </p>
                <p>
                    <b>Jak se registrovat?</b>
                </p>
                <p>
                    Osoba povinná k dani uvedená v{' '}
                    <a
                        href={
                            'https://www.podnikatel.cz/zakony/zakon-c-235-2004-sb-o-dani-z-pridane-hodnoty/' +
                            'zneni-20160101/uplne/#f2548162'
                        }
                    >
                        § 6 zákona o DPH{' '}
                    </a>{' '}
                    je povinna podat přihlášku k registraci do 15 dnů po skončení kalendářního měsíce, ve kterém
                    překročila stanovený obrat. Plátce uvedený v{' '}
                    <a
                        href="https://www.podnikatel.cz
          /zakony/zakon-c-235-2004-sb-o-dani-z-pridane-hodnoty/zneni-20160101/uplne/#f4984336"
                    >
                        § 6a až 6e zákona o DPH
                    </a>{' '}
                    je povinen podat přihlášku k registraci do 15 dnů ode dne, ve kterém se stal plátcem.
                </p>
                <p>
                    Nejjednodušším způsobem, jak vyplnit a podat přihlášku, je elektronickou formou. K samotnému
                    vyplnění nepotřebujete vlastnit ani elektronický podpis, ani datovou schránku. Pomocí pohodlného
                    průvodce na stránkách
                    <a
                        href="https://adisepo.mfcr.cz/adistc/adis/idpr_epo/epo2/uvod/vstup_expert.faces"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        Daňového portálu Finanční správy{' '}
                    </a>{' '}
                    můžete tento formulář vyplnit, následně jej vytisknout a poté zaslat poštou nebo elektronicky na
                    finanční úřad nebo osobně podat na podatelně.
                </p>
            </div>
        </div>
    );
};
