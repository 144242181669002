import { TextInput } from '../../../components/TextInput';
import { passwordValidator } from '../personal.validators';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { setPersonalInfoField, touchPersonalInfoField } from '../personalInfoSlice';

interface PasswordFieldProps {
    width: string;
    isLoading: boolean;
}

export const PasswordField = (props: PasswordFieldProps) => {
    const dispatch = useAppDispatch();
    const { password, showPassword, passwordTouched, fieldName } = useAppSelector((state) => ({
        password: state.personalInfo.password,
        fieldName: 'personalPassword',
        showPassword: state.personalInfo.showPassword,
        passwordTouched: state.personalInfo.passwordTouched,
    }));

    return (
        <TextInput
            className={`${props.width}`}
            value={password}
            label={'registration.personalData.password'}
            inputType={'password'}
            disabled={props.isLoading}
            required
            name={fieldName}
            onChange={(evt) => dispatch(setPersonalInfoField({ fieldName: 'password', value: evt.target.value }))}
            onFocus={() => dispatch(touchPersonalInfoField('password'))}
            success={showPassword ? password : undefined}
            error={passwordTouched ? passwordValidator(password) : undefined}
            showDisplayPasswordToggle={true}
        />
    );
};
