import { useAppSelector } from '../../configuration/setup/hooks';
import { Region, regionSelector } from '../region/regionSlice';
import { CompanyInfoHeader } from './CompanyInfoHeader';
import { LatamCompanyInfo } from './latam/LatamCompanyInfo';
import { EuropeCompanyInfo } from './europe/EuropeCompanyInfo';

export const CompanyInfo = () => {
    const { region } = useAppSelector((state) => ({ region: regionSelector(state) }));
    return (
        <div className={'RegistrationPanelContainer panel-default panel panel-body padding-25'}>
            <CompanyInfoHeader />
            <div className={'row'} />
            {region === Region.latinamerica ? <LatamCompanyInfo /> : <EuropeCompanyInfo />}
        </div>
    );
};
