export const PlPL = () => {
    return (
        <div>
            <h3 className="popover-title">Co to jest NIP UE i jaki jest jego cel?</h3>
            <div className="popover-content">
                <p>
                    NIP UE to nic innego jak Numer Identyfikacji Podatkowej, który służy do identyfikacji podatników na
                    terenie Unii Europejskiej. Jest to bowiem numer podatnika VAT stworzony na potrzeby handlu
                    wewnątrzwspólnotowego. Jego uzyskanie nie stanowi większego problemu, wystarczy bowiem złożyć
                    wniosek o nadanie numeru NIP EU w Urzędzie Skarbowym.{' '}
                </p>
                <p>
                    Wszelkie informacje dotyczące uzyskania numeru NIP UE można znaleźć na stronie{' '}
                    <a
                        href="https://www.podatki.gov.pl/abc-podatkow/rejestracja-podatnikow/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://www.podatki.gov.pl/abc-podatkow/rejestracja-podatnikow/
                    </a>
                </p>
            </div>
        </div>
    );
};
