export const SkSK = () => {
    return (
        <div>
            <h3 className="popover-title">Čo je DPH a aký je účel?</h3>
            <div className="popover-content">
                <p>
                    Daň z pridanej hodnoty je daňou vyberanou vládou z predaja tovarov a služieb. Všetky podniky,
                    ktorých ročný obrat presahuje súčasnú hranicu DPH - v súčasnosti 49 790 eur - sa musia zaregistrovať
                    na účely DPH a vyplniť štvrťročné daňové priznanie k DPH.{' '}
                </p>
                <p>
                    <b>Ako sa zaregistrovať</b>
                </p>
                <p>
                    Väčšina firiem sa môže zaregistrovať online. Týmto spôsobom sa zaregistrujete na účely DPH a
                    vytvoríte účet online na účely DPH (niekedy nazývaný aj ako &quot;účet verejnej brány&quot;).
                </p>
                <a href="https://www.financnasprava.sk/en/homepage" target="_blank" rel="noreferrer">
                    https://www.financnasprava.sk/en/homepage
                </a>
            </div>
        </div>
    );
};
