import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { isPersonalInfoValid } from '../personal.validators';
import { isAppInMaintenanceMode } from '../../../configuration/tmpSlice';

export const PersonalNextButton = ({ isLoading, onClick }: { isLoading: boolean; onClick: () => void }) => {
    const { isValid } = useAppSelector((state) => ({
        isValid: !isAppInMaintenanceMode(state) && isPersonalInfoValid(state.personalInfo),
    }));

    const intl = useIntl();

    return (
        <div className={'col-xs-6 text-right'}>
            {isLoading ? (
                <button disabled className={'btn btn-primary disabled'}>
                    <Spinner isInverse text={intl.formatMessage({ id: 'registration.personalData.nextButtonLabel' })} />
                </button>
            ) : (
                <button
                    disabled={!isValid}
                    onClick={(e) => {
                        e.preventDefault();
                        onClick();
                    }}
                    className={'btn btn-primary btn-icon-right finalize-registration-button'}
                >
                    <span className={'rioglyph rioglyph-chevron-right'} />
                    <FormattedMessage id={'registration.personalData.nextButtonLabel'} />
                </button>
            )}
        </div>
    );
};
