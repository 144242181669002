import { connect } from 'react-redux';
import { TextInput } from '../../../components/TextInput';
import {
    mapDispatchToPersonalFirstNameProps,
    mapDispatchToPersonalLastNameProps,
    mapStateToPersonalFirstNameProps,
    mapStateToPersonalLastNameProps,
} from './personalElement';
import { nameValidator } from '../personal.validators';

interface NameFieldProps {
    width: string;
    error?: string;
    required?: boolean;
    showPopoverTooltip?: boolean;
    popoverTooltipMessage?: string;
    name?: string;

    fieldLabel: string;
    fieldValid: boolean;
    fieldTouched: boolean;
    fieldValue: string;
    fieldDisabled?: boolean;

    setFieldValue(value: string): void;
    touchField(): void;
}

export const NameField = (props: NameFieldProps) => {
    return (
        <TextInput
            className={'col-xs-12 ' + props.width}
            value={props.fieldValue}
            label={props.fieldLabel}
            name={props.name}
            popoverTooltip={props.showPopoverTooltip}
            popoverTooltipMessage={props.popoverTooltipMessage}
            disabled={props.fieldDisabled}
            required={props.required}
            onChange={(evt: any) => props.setFieldValue(evt.target.value)}
            error={props.fieldTouched ? nameValidator(props.fieldValue) : undefined}
            onBlur={(evt: any) => {
                props.setFieldValue(evt.target.value && evt.target.value.trim());
                props.touchField();
            }}
        />
    );
};

NameField.defaultProps = {
    required: true,
};

export const FirstNameFieldContainer = connect(
    mapStateToPersonalFirstNameProps,
    mapDispatchToPersonalFirstNameProps,
)(NameField);

export const LastNameFieldContainer = connect(
    mapStateToPersonalLastNameProps,
    mapDispatchToPersonalLastNameProps,
)(NameField);
