import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../config';
import { RegistrationRequest } from './apiTypes';
import { handleResponse, prepareHeaders } from './apiServiceUtils';

export const registrationApi = createApi({
    reducerPath: 'registrationApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.REGISTRATION_SERVICE, prepareHeaders }),
    endpoints: (builder) => ({
        sendRegistration: builder.mutation<void, RegistrationRequest>({
            query: (postBody) => ({
                url: '',
                method: 'POST',
                body: postBody,
                responseHandler: async (response: Response) => handleResponse(response),
            }),
        }),
    }),
});

export const { useSendRegistrationMutation } = registrationApi;
