import { DEFAULT_LOCALE, extractLanguage } from '../../configuration/lang/lang';
import { Region } from '../region/regionSlice';

const KNOWN_LINK_LOCALES = [
    'bg',
    'cz',
    'de',
    'dk',
    'ee',
    'en',
    'es',
    'fi',
    'fr',
    'gr',
    'hr',
    'hu',
    'it',
    'lt',
    'lv',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'se',
    'si',
    'sk',
];

const keyToDoc: Map<string, (lang: string) => string> = new Map([
    [
        'access',
        (lang) =>
            `https://rio.cloud/${getValidLocalePathSegmentFromLocale(lang)}/documents/general/technical-requirements`,
    ],
    [
        'conditions',
        (lang) => `https://rio.cloud/${getValidLocalePathSegmentFromLocale(lang)}/documents/general/terms-of-service`,
    ],
    [
        'contract',
        (lang) =>
            `https://rio.cloud/${getValidLocalePathSegmentFromLocale(
                lang,
            )}/documents/general/contract-processing-agreement`,
    ],
    [
        'privacy',
        (lang) => `https://rio.cloud/${getValidLocalePathSegmentFromLocale(lang)}/documents/general/data-protection`,
    ],
    [
        'serviceSpecs',
        (lang) =>
            `https://rio.cloud/fileadmin/Marketplace/RIO/Products/Essentials/Localized/${lang}/Essentials_DOS.pdf`,
    ],
    [
        'serviceTerms',
        (lang) =>
            `https://rio.cloud/fileadmin/Marketplace/RIO/Products/Essentials/Localized/${lang}/Essentials_TOS.pdf`,
    ],
    [
        'serviceSpecsServiceCareS',
        (lang) => `https://rio.cloud/${getValidLocalePathSegmentFromLocale(lang)}/documents/man-serviecare/dos/s`,
    ],
    [
        'serviceTermsServiceCareS',
        (lang) => `https://rio.cloud/${getValidLocalePathSegmentFromLocale(lang)}/documents/man-serviecare/tos/s-m`,
    ],
]);

const isLatam = (region: string): boolean => {
    return region === Region.latinamerica;
};

export const makeUrl = (key: string, lang: string, region: string) => {
    const languageKeyForTopLevelPages =
        extractLanguage(lang) === 'de' && !isLatam(region) ? 'de' : extractLanguage(DEFAULT_LOCALE);
    if (isLatam(region)) {
        switch (key) {
            case 'privacyToplevel':
                return 'https://americas.rio.cloud/rodape/protecao-de-dados';
            case 'imprintToplevel':
                return 'https://americas.rio.cloud/rodape/ficha-tecnica';
            case 'conditions':
                return 'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Platform_legal_documents/4_PT_general-terms-and-conditions.pdf';
            case 'contract':
                return 'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Platform_legal_documents/5_PT_order_processing.pdf';
            case 'privacy':
                return 'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Platform_legal_documents/3_PT_data-protection.pdf';
            case 'access':
                return 'https://americas.rio.cloud/fileadmin/Platform_legal_documents/pt-BR/Technical-requirements.pdf';
            case 'serviceSpecs':
                return 'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Platform_legal_documents/11_PT_service_terms_and_conditions.pdf';
            case 'serviceTerms':
                return 'https://americas.rio.cloud/fileadmin/Region/Americas/Website/Platform_legal_documents/11_PT_service_terms_and_conditions.pdf';
        }
    } else {
        switch (key) {
            case 'privacyToplevel':
                return `https://rio.cloud/${languageKeyForTopLevelPages}/data-protection`;
            case 'imprintToplevel':
                return `https://rio.cloud/${languageKeyForTopLevelPages}/imprint`;
        }
    }

    const getLinkForLanguage = keyToDoc.get(key);
    return getLinkForLanguage ? getLinkForLanguage(lang) : undefined;
};

const getValidLocalePathSegmentFromLocale = (locale: string) => {
    const fallbackLocale = 'en';
    let localePrefix: string;
    switch (locale) {
        case 'nb-NO': {
            localePrefix = 'no';
            break;
        }
        default: {
            localePrefix = locale.split('-')[0];
        }
    }

    return KNOWN_LINK_LOCALES.includes(localePrefix) ? localePrefix : fallbackLocale;
};
